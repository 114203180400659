import React from "react";

import { Route, Routes } from "react-router-dom";

import UserSelector from "./UserSelector";
import Messaging from "./Messaging";

import "./core.sass";

const MessagingView = () => {
  return (
    <Routes>
      <Route index element={<Messaging />} />
      <Route path={"new"} element={<UserSelector />} />
      <Route path={":id"} element={<Messaging />} />
    </Routes>
  );
};

export default MessagingView;
