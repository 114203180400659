import React, { ReactNode, useEffect } from "react";

// React packages
import { NavLink } from "react-router-dom";

// GraphQL
import { useSubscription } from "@apollo/client";

// Third-party packages
import { v4 as uuidv4 } from "uuid";

import "./Navigation.sass";

import { ReactComponent as ActivitySVG } from "../../assets/svg/icons/navigation/main_heart.svg";
import { ReactComponent as ExploreSVG } from "../../assets/svg/icons/navigation/main_explore.svg";
import { ReactComponent as MessagesSVG } from "../../assets/svg/icons/navigation/main_messages.svg";

// Notifications
import useNotification from "../core/Notification";

import { Segment } from "../../types";

import { NOTIFICATION_SUBSCRIPTION } from "./queries";

type LinkProps = {
  title: string;
  className: string;
  children: ReactNode;
  path: string;
  active?: boolean;
  notification?: boolean;
  onClick: () => void;
};

const Link = ({
  title,
  className,
  children,
  path,
  notification,
}: LinkProps) => (
  <div className="nav-item">
    <NavLink
      to={path}
      className={(navData) =>
        navData.isActive ? `active ${className}` : className
      }
    >
      <div className="image">{children}</div>
      <span className="title">{title}</span>
      {notification ? <span className="notification"></span> : ""}
    </NavLink>
  </div>
);

const Navigation = () => {
  const { data } = useSubscription(NOTIFICATION_SUBSCRIPTION);

  const { notifications, addNotification, removeNotification } =
    useNotification();

  useEffect(() => {
    if (data && data.notificationSubscription) {
      addNotification({
        id: uuidv4(),
        text: data.notificationSubscription.text,
        segment: data.notificationSubscription.segment,
        type: data.notificationSubscription.type,
        action: data.notificationSubscription.action,
        subselection: data.notificationSubscription.subselection,
        author: data.notificationSubscription.author,
      });
    }
  }, [data]); // eslint-disable-line

  const aNotifs = notifications.filter((n) => n.segment === Segment.ACTIVITY);
  const pNotifs = notifications.filter((n) => n.segment === Segment.PEOPLE);
  const mNotifs = notifications.filter((n) => n.segment === Segment.MESSAGES);

  return (
    <nav className={"navigation"}>
      <div className="nav-wrap">
        <Link
          title={"Activity"}
          path={"/activity"}
          notification={aNotifs.length > 0}
          className={"activity"}
          onClick={() =>
            aNotifs.map(
              (n) => !n.subselection?.length && removeNotification(n.id)
            )
          }
        >
          <ActivitySVG />
        </Link>
        <Link
          title={"People"}
          path={"/people"}
          notification={pNotifs.length > 0}
          className={"people"}
          onClick={() =>
            pNotifs.map(
              (n) => !n.subselection?.length && removeNotification(n.id)
            )
          }
        >
          <ExploreSVG />
        </Link>
        <Link
          title={"Messages"}
          path={"/messages"}
          active={true}
          notification={mNotifs.length > 0}
          className={"messages"}
          onClick={() =>
            mNotifs.map(
              (n) => !n.subselection?.length && removeNotification(n.id)
            )
          }
        >
          <MessagesSVG />
        </Link>
      </div>
    </nav>
  );
};
export default Navigation;
