import React, { useState } from "react";

import { gql, useMutation } from "@apollo/client";

// Types
import { DialogueType, UserType } from "../../types";

// Components
import ActionButton from "../core/ActionButton";
import CancelButton from "../core/CancelButton";

// Utils
import { setError } from "../core/utils";

// Queries
import { SEND_ECHOS_MUTATION } from "./queries";
import { ME_QUERY } from "../accounts/queries";

// Third party packages
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import TextareaAutosize from "react-textarea-autosize";

// Styles
import "./TradeInput.sass";

type HeaderProps = {
  title: string;
  prevPath: string;
  onCancel: () => void;
  onNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Header = ({ title, prevPath, onCancel, onNext }: HeaderProps) => {
  return (
    <div className="header">
      <CancelButton pathname={prevPath} onClick={onCancel} />
      <div className="title">{title}</div>
      <ActionButton title={"Give"} onClick={onNext} />
    </div>
  );
};

type TradeInputProps = {
  dialogue: DialogueType;
  user?: UserType;
  prevPath: string;
  onCancel: () => void;
  onComplete: () => void;
};

const DEFAULT_OFFER_ID = "0";
const DEFAULT_TIME = "1";

export const TradeInput = ({
  dialogue,
  user,
  onCancel,
  onComplete,
  prevPath,
}: TradeInputProps) => {
  const [sendEchos] = useMutation(SEND_ECHOS_MUTATION, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    },
    refetchQueries: [{ query: ME_QUERY }],
    update(cache, { data: { sendEchos } }) {
      cache.modify({
        id: cache.identify({
          __typename: "DialogueType",
          id: dialogue.id,
        }),
        fields: {
          messages(existingMessages = []) {
            const newMessageRef = cache.writeFragment({
              data: sendEchos.message,
              fragment: gql`
                fragment NewTradeMessage on MessageType {
                  id
                  text
                  author
                  trade
                  own
                  timestamp
                }
              `,
            });
            return [...existingMessages, newMessageRef];
          },
        },
      });
    },
  });

  const [state, setState] = useState({
    time: DEFAULT_TIME,
    offerId: DEFAULT_OFFER_ID,
    post: "",
    error: false,
  });

  const handleSendEchosButtonClick = () => {
    const cBal = user?.profile?.balance;
    if (
      cBal !== undefined &&
      parseInt(state.time) >= 1 &&
      parseInt(state.time) <= cBal
    ) {
      sendEchos({
        variables: {
          dialogueId: dialogue.id,
          time: parseInt(state.time),
          post: state.post,
          offerId: state.offerId,
        },
      });
      onComplete();
    } else {
      setState({ ...state, error: true });
    }
  };

  function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
  }

  const dataOptions = dialogue.users
    .map((u) =>
      u.profile?.offers
        ?.map((o) => ({ value: o.id || DEFAULT_OFFER_ID, label: o.title }))
        .filter(notUndefined)
    )
    .filter(notUndefined);

  const options = [{ value: DEFAULT_OFFER_ID, label: "Gift" }].concat(
    ...dataOptions
  );

  return (
    <div className="trade-input">
      <Header
        title={"Give Echos"}
        prevPath={prevPath}
        onCancel={onCancel}
        onNext={handleSendEchosButtonClick}
      />

      <div className="fields">
        <div className={`field trade-time${state.error ? " error" : ""}`}>
          <label htmlFor="time">Time it took</label>
          <small>1 hour = 1 Echo</small>
          <input
            name="time"
            placeholder="1"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={state.time}
            onChange={(e) => {
              setState({ ...state, time: e.target.value });
            }}
          />
        </div>
        <div className="field trade-offer">
          <label htmlFor="offer">What was it for?</label>
          <Dropdown
            options={options}
            onChange={(option) => setState({ ...state, offerId: option.value })}
            value={state.offerId}
            placeholder="Select an offer"
          />
        </div>
        <div className="field trade-thank-you">
          <label htmlFor="thank-you">Write a thank you</label>
          <small>
            We’ll share this on the Echo feed. See it as a mix between a public
            thank you and a record of what you did. Thank yous help other Echo
            members know what’s happening in the community.
          </small>
          <div className="textarea-wrapper">
            <TextareaAutosize
              minRows={3}
              maxRows={5}
              placeholder="Write some nice words to share on the Echo feed."
              onChange={(ev) => setState({ ...state, post: ev.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
