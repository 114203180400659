import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Community1SVG } from "../../assets/svg/brand/Community 1.svg";

import { ROUTES } from "../../constants";

const CreateOffer = () => {
  return (
    <div className="widget create-offer">
      <div className="widget-heading f-b">Post an offer</div>
      <div className="widget-image">
        <Community1SVG className="offers-image" />
      </div>
      <div className="widget-url f-b">
        <Link
          to={{
            pathname: `${ROUTES.OFFER}/new`,
          }}
        >
          Post your offer
        </Link>
      </div>
    </div>
  );
};

export default CreateOffer;
