import React from "react";

import { Link } from "react-router-dom";

import Slider from "react-slick";

import { UserType, OfferType } from "../../types";

import { ROUTES } from "../../constants";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./CarouselList.sass";

type OfferItemProps = {
  user: UserType;
  offer: OfferType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const OfferItem = ({ user, offer }: OfferItemProps) => (
  <div className="item">
    <div className="item-title f-b">{offer.title}</div>
    <div className="item-short">{offer.short}</div>
    <div className="item-url">
      <Link
        to={{
          pathname: `${ROUTES.PROFILE}/${user.id}/${offer.id}`,
        }}
      >
        View full offer
      </Link>
    </div>
  </div>
);

type OfferListProps = {
  user: UserType;
};

const OfferList = ({ user }: OfferListProps) => (
  <div className="carousel-list">
    <Slider dots={true} arrows={false}>
      {user.profile?.offers?.map(
        (offer: OfferType, index: string | number | undefined) => (
          <OfferItem
            user={user}
            offer={offer}
            key={index}
            onClick={() => null}
          />
        )
      )}
    </Slider>
  </div>
);

export default OfferList;
