import React, { useEffect, useState } from "react";

import { ME_QUERY } from "../accounts/queries";

import ActionButton from "../core/ActionButton";

import { ReactComponent as AlertCircleSVG } from "../../assets/svg/icons/alert-circle.svg";
import "./Banner.sass";
import { useMutation, useQuery } from "@apollo/client";
import { RESEND_ACTIVATION_EMAIL_MUTATION } from "../accounts/queries";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

export const Banner = () => {
  const [state, setState] = useState({
    show: false,
    showButton: true,
  });

  const { loading, error, data } = useQuery(ME_QUERY);

  const [resendActivationEmail, resultResendActivationEmail] = useMutation(
    RESEND_ACTIVATION_EMAIL_MUTATION
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.me) {
      setState({
        ...state,
        show: !data.me.verified || !data.me.profile.complete,
      });
    }
  }, [data, state]);

  useEffect(() => {
    if (
      resultResendActivationEmail.data &&
      resultResendActivationEmail.data.success
    ) {
      setState({ ...state, showButton: false });
    }
  }, [resultResendActivationEmail.data, state]);

  if (loading || error) return null;

  if (!data.me.verified) {
    return (
      <div
        className="banner cntrlse"
        style={{ display: state.show ? "block" : "none" }}
      >
        <div className="banner-wrap">
          <div className={`banner-content${state.showButton ? "" : " sent"}`}>
            <div className="message">
              <AlertCircleSVG className="icon" /> Please check your email inbox
              to verify your account.
            </div>
            <ActionButton
              title={"Resend Email"}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                resendActivationEmail({
                  variables: { email: data.me.email },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  } else if (!data.me.profile.complete) {
    return (
      <div
        className="banner cntrlse"
        style={{ display: state.show ? "block" : "none" }}
      >
        <div className="banner-wrap">
          <div className={`banner-content${state.showButton ? "" : " sent"}`}>
            <div className="message">
              <AlertCircleSVG className="icon" /> Please complete your profile.
            </div>
            <ActionButton
              title={"Update profile"}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                navigate(`${ROUTES.PROFILE}/${data.me.id}/edit`);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};
