import React, { useState, useRef } from "react";

import { gql, useMutation } from "@apollo/client";

import { DialogueType, MessageType } from "../../types";

import { setError } from "../core/utils";
import { CREATE_MESSAGE_MUTATION } from "./queries";

import "./MessageInput.sass";

// Assets
import BUTTON_ADD from "../../assets/svg/icons/button/add.svg";
import { ReactComponent as SendSVG } from "../../assets/svg/icons/send.svg";

import TextareaAutosize from "react-textarea-autosize";

type MessageInputProps = {
  dialogue: DialogueType;
  message?: MessageType;
  messageButtonLabel?: string;
  onAddButtonClick: () => void;
};

export const MessageInput = ({
  dialogue,
  message = { text: "" },
  onAddButtonClick,
}: MessageInputProps) => {
  const [status, setStatus] = useState({ message: message });
  const textArea = useRef(null);

  const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION, {
    onError: (error) => {
      setError(error.message);
    },
    update(cache, { data: { createMessage } }) {
      cache.modify({
        id: cache.identify({
          __typename: "DialogueType",
          id: dialogue.id,
        }),
        fields: {
          messages(existingMessages = []) {
            const newMessageRef = cache.writeFragment({
              data: createMessage.message,
              fragment: gql`
                fragment NewMessage on MessageType {
                  id
                  text
                  author
                  own
                  timestamp
                }
              `,
            });
            return [...existingMessages, newMessageRef];
          },
        },
      });
    },
  });

  const handleTextareaChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    ev.preventDefault();
    setStatus({
      message: {
        ...status.message,
        text: (textArea as any).current.value,
      },
    });
  };

  // TODO: Add UseEffect to disable input whilst round-trip underway?
  const handleClick = () => {
    if (status.message.text.trim() !== "") {
      createMessage({
        variables: {
          dialogueId: dialogue.id,
          text: status.message.text.trim(),
        },
      });
      setStatus({
        message: {
          ...status.message,
          text: "",
        },
      });
    }
  };

  return (
    <div
      className={`message-input${status.message.text.length ? " active" : ""}`}
    >
      <div className="input-wrap">
        <div className="add">
          <img src={BUTTON_ADD} onClick={onAddButtonClick} alt="" />
        </div>
        <div className="input-group">
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            placeholder="Message"
            className="form-control"
            aria-label="With textarea"
            id="message-input-textarea"
            ref={textArea}
            onChange={handleTextareaChange}
            value={status.message.text}
          />
        </div>
        <div className="send">
          <button onClick={handleClick}>
            <SendSVG />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
