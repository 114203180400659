import React, { useState } from "react";

import plusSVGURL from "../../assets/svg/brand/plus.svg";
import "./BalanceToast.sass";

type BalanceToastProps = {
  balance: number;
  show: boolean;
  onCloseClick: () => void;
};

export const BalanceToast = ({
  balance,
  show,
  onCloseClick,
}: BalanceToastProps) => {
  const [state] = useState({
    show: show,
  });
  return (
    <div
      className="balanceToast"
      style={{ display: state.show ? "block" : "none" }}
    >
      <div className="balanceToastWrap">
        <div className="balanceToastContent">
          <div className="message">
            <h4>Echos</h4>
            You have <img src={plusSVGURL} alt="Echos" />
            <strong>
              {balance} Echo hour{balance > 1 && "s"}
            </strong>
            &nbsp;to use getting help from other Echo members. <strong>How do I get Echos?</strong> For
            every hour you help someone, you earn another Echo.
          </div>
          <div>
            <span className="btn close" onClick={onCloseClick}>
              Got it
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
