import React from "react";
import { Link, useLocation } from "react-router-dom";

// Types
import { ActionType, OfferType, TradeType } from "../../types";

// Assets
import { ReactComponent as CaseSVG } from "../../assets/svg/icons/case.svg";

// Styles
import "./ActionItem.sass";

// Utils
import { formatTime } from "../core/utils";

// Constants
import { ActivityType } from "../../constants";

type ActionItemProps = {
  action: ActionType;
};

const ActionItem = ({ action }: ActionItemProps) => {
  const location = useLocation();

  const { actor, target, verb } = action;

  const actionInfo =
    verb ===  ActivityType.JOINED ? (
      <div className="action-info action-join">
        <div className="action-title">👋 Someone just joined Echo!</div>
        <div className="actor">
          <div className="image">
            <Link to={{ pathname: `/profile/${actor.id}` }}>
              {actor.profile?.picture && (
                <img src={actor.profile?.picture} alt={actor.name} />
              )}
            </Link>
          </div>
          <div className="meta">
            <span className="name">
              <Link to={{ pathname: `/profile/${actor.id}` }}>
                {actor.profile?.preferredName}
              </Link>
            </span>
            {actor.profile?.project && (
              <span className="project">
                <CaseSVG />
                {actor.profile?.project}
              </span>
            )}
          </div>
        </div>
      </div>
    ) : (target as OfferType).title ? (
      <div className="action-info action-offer">
        <div className="action-title">📌 New offer posted</div>
        <div className="actor">
          <div className="image">
            <Link to={{ pathname: `/profile/${actor.id}` }}>
              {actor.profile?.picture && (
                <img src={actor.profile?.picture} alt={actor.name} />
              )}
            </Link>
          </div>
          <div className="meta">
            <span className="name">
              <Link to={{ pathname: `/profile/${actor.id}` }}>
                {actor.profile?.preferredName}
              </Link>
            </span>
            {actor.profile?.project && (
              <span className="project">
                <CaseSVG />
                {actor.profile?.project}
              </span>
            )}
          </div>
        </div>
        <div className="target">
          <div className="details">
            <div className="details-title">{(target as OfferType).title}</div>
            <div className="details-short">{(target as OfferType).short}</div>
            <div className="details-url">
              <Link
                to={{
                  pathname: `/profile/${actor.id}/${target.id}`,
                }}
                state={{ prevPath: location.pathname }}
              >
                View full offer
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="action-info action-trade">
        <div className="action-title">
          <span>
            <strong>🎉{" "} 
              <Link
                to={{
                  pathname: `/profile/${(target as TradeType).recipient?.id}`,
                }}
              >
                {(target as TradeType).recipient?.profile?.preferredName}
              </Link>
            </strong>{" "}
            traded with{" "}
            <strong>
              <Link
                to={{
                  pathname: `/profile/${(target as TradeType).supplier?.id}`,
                }}
              >
                {(target as TradeType).supplier?.profile?.preferredName}
              </Link>
            </strong>
          </span>
        </div>
        <div className="actor">
          <div className="image">
            <Link
              to={{
                pathname: `/profile/${(target as TradeType).supplier?.id}`,
              }}
            >
              {(target as TradeType).supplier?.profile?.picture && (
                <img
                  src={(target as TradeType).supplier?.profile?.picture}
                  alt={(target as TradeType).supplier?.profile?.preferredName}
                />
              )}
            </Link>
          </div>
          <div className="meta">
            <span className="name">
              <Link
                to={{
                  pathname: `/profile/${(target as TradeType).supplier?.id}`,
                }}
              >
                {(target as TradeType).supplier?.profile?.preferredName}
              </Link>
            </span>
            {(target as TradeType).supplier?.profile?.project && (
              <span className="project">
                <CaseSVG />
                {(target as TradeType).supplier?.profile?.project}
              </span>
            )}
          </div>
        </div>
        <div className="target">
          <div className="details">
            {(target as TradeType).offer && (
              <div className="details-title">
                {(target as TradeType).offer?.title}
              </div>
            )}
            {(target as TradeType).note && (
              <div className="details-short">
                <div className="details-image">
                  <div className="image">
                    <Link
                      to={{
                        pathname: `/profile/${
                          (target as TradeType).recipient?.id
                        }`,
                      }}
                    >
                      {(target as TradeType).recipient?.profile?.picture && (
                        <img
                          src={
                            (target as TradeType).recipient?.profile?.picture
                          }
                          alt={
                            (target as TradeType).recipient?.profile
                              ?.preferredName
                          }
                        />
                      )}
                    </Link>
                  </div>
                </div>
                <div className="details-info">
                  <div className="details-meta">
                    <div className="details-meta-recipient">
                      <h6 className="recipient-name">
                        {
                          (target as TradeType).recipient?.profile
                            ?.preferredName
                        }
                      </h6>
                      <span className="recipient-bio">
                        {(target as TradeType).recipient?.profile?.bio}
                      </span>
                    </div>
                    <div className="details-meta-time">
                      {formatTime((target as TradeType).note?.timeCreated)}
                    </div>
                  </div>
                  <div className="note">
                    <div className="text">
                      {(target as TradeType).note?.text}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  return <div className="action-item">{actionInfo}</div>;
};

export default ActionItem;
