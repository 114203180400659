import { gql } from "@apollo/client";

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NotificationSubscription {
    notificationSubscription {
      id
      segment
      type
      action
      text
      subselection
      author {
        id
        profile {
          id
          preferredName
        }
      }
      own
    }
  }
`;
