import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

// Components
import FormHeader from "./FormHeader";
import ActionButton from "../core/ActionButton";
import { FormTitle } from "../core/Forms";
import { Toast } from "../core/Toast";
import { FormTextInput } from "./Inputs";
import { TextSignUp } from "./core";

// Configuration
import { ROUTES } from "../../constants";

// Queries
import { SEND_PASSWORD_RESET_EMAIL_MUTATION } from "./queries";

// Assets
import { ErrorType } from "../../types";

enum Step {
  Start,
  Complete,
}

const SendPasswordResetEmail = () => {
  const [formState, setFormState] = useState({
    email: "",
    errors: [] as ErrorType[],
    step: Step.Start,
  });

  const [sendPasswordResetEmail, resultSendPasswordResetEmail] = useMutation(
    SEND_PASSWORD_RESET_EMAIL_MUTATION
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (resultSendPasswordResetEmail.data) {
      const success =
        resultSendPasswordResetEmail.data.sendPasswordResetEmail.success;
      if (success) {
        setFormState({
          ...formState,
          errors: [],
          step: Step.Complete,
        });
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message:
                "There was an issue resetting your password. Please check and try again.",
            },
          ],
        });
      }
    }
  }, [resultSendPasswordResetEmail.data, formState]);

  const validateEmail = () => {
    if (formState.email !== "") {
      return true;
    }
    return false;
  };

  return (
    <div className="auth contained">
      <div className="reset-mode">
        <div className="password-reset">
          <form>
            {formState.step === Step.Start && (
              <div className="email">
                <FormHeader
                  onBackLinkClick={() => {
                    navigate(ROUTES.LOGIN);
                  }}
                />
                <FormTitle title={"Reset"} />
                <FormTextInput
                  placeholder="Enter your email"
                  value={formState.email}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      email: e.target.value.toLowerCase(),
                    })
                  }
                  error={formState.errors.length > 0}
                />
                {formState.errors.length !== 0 && (
                  <React.Fragment>
                    <div className="error">{formState.errors[0].message}</div>
                    <Toast
                      show={true}
                      className="error"
                      message="Something wasn’t right"
                    />
                  </React.Fragment>
                )}
                <ActionButton
                  type="btn-next"
                  title="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (validateEmail()) {
                      sendPasswordResetEmail({
                        variables: { email: formState.email },
                      });
                    }
                  }}
                />
              </div>
            )}
            {formState.step === Step.Complete && (
              <div className="complete">
                <FormHeader
                  onBackLinkClick={() => {
                    navigate(ROUTES.LOGIN);
                  }}
                />
                <FormTitle title={"Reset"} />
                <h4>Great, you’re done. Please check your email</h4>
              </div>
            )}
          </form>
        </div>
        <TextSignUp />
      </div>
    </div>
  );
};

export default SendPasswordResetEmail;
