import React, { MouseEvent, useState, useEffect } from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";

// Queries
import { TOKEN_AUTH_MUTATION } from "./queries";

// Data
import { ErrorType } from "../../types";

// Components
import ActionButton from "../core/ActionButton";
import FormHeader from "./FormHeader";
import { Toast } from "../core/Toast";

// Utilities
import { setError } from "../core/utils";

// Styles
import "./core.sass";

import { isAuthenticated, saveUser } from "./core";

import { ROUTES } from "../../constants";

type FormTitleProps = {
  title?: string;
};

const FormTitle = ({ title }: FormTitleProps) => (
  <div className="form-title f-b">{title}</div>
);

type FormInputProps = {
  title: string;
  placeholder: string;
  autocomplete?: string;
  onChange: (val: string) => void;
};

const FormTextInput = ({
  title,
  placeholder,
  autocomplete,
  onChange,
}: FormInputProps) => {
  const handleChange = (evt: { target: { value: string } }) => {
    onChange(evt.target.value);
  };
  return (
    <div className="form-input">
      <div className="title f-b">{title}</div>
      <div className="field">
        <input
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          autoComplete={autocomplete}
        />
      </div>
    </div>
  );
};

const FormPasswordInput = ({
  title,
  placeholder,
  autocomplete,
  onChange,
}: FormInputProps) => {
  const handleChange = (evt: { target: { value: string } }) => {
    onChange(evt.target.value);
  };
  return (
    <div className="form-input">
      <div className="title f-b">{title}</div>
      <div className="field">
        <input
          type="password"
          placeholder={placeholder}
          onChange={handleChange}
          autoComplete={autocomplete}
        />
      </div>
    </div>
  );
};

const TextPasswordReset = () => (
  <div className="text-footnote">
    Forgotten your password? <a href="/password-reset">Reset</a>
  </div>
);

type EmailEntryProps = {
  active: boolean;
  onChange: (email: string) => void;
};

const EmailEntry = ({ active, onChange }: EmailEntryProps) => (
  <div className={"email-entry" + (!active ? "" : " hidden")}>
    <FormTitle title={"Log in"} />
    <FormTextInput
      onChange={onChange}
      title="Email"
      placeholder="Enter your email"
      autocomplete="email"
    />
  </div>
);

type PasswordEntryProps = {
  active: boolean;
  errors: ErrorType[];
  onChange: (password: string) => void;
};

const PasswordEntry = ({ active, errors, onChange }: PasswordEntryProps) => (
  <div className={"password-entry" + (active ? "" : " hidden")}>
    <FormTitle title={"Password"} />
    {errors.length !== 0 && (
      <React.Fragment>
        <div className="error">{errors[0].message}</div>
        <Toast show={true} className="error" message="Something wasn’t right" />
      </React.Fragment>
    )}
    <FormPasswordInput
      onChange={onChange}
      title="Password"
      placeholder="Enter your password"
      autocomplete="current-password"
    />
  </div>
);

const LogInForm = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    login: false,
    next: false,
    authenticated: false,
    errors: [] as ErrorType[],
  });

  const [tokenAuth, result] = useMutation(TOKEN_AUTH_MUTATION, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    },
  });

  const locationState = useLocation().state as { from: string };
  const navigate = useNavigate();

  // TODO: Ensure that this doesn't generate Maximum Depth Exceeded error.
  useEffect(() => {
    if (result.data) {
      if (result.data.tokenAuth.success) {
        saveUser(
          result.data.tokenAuth.user,
          result.data.tokenAuth.token,
          result.data.tokenAuth.refreshToken
        );
        setState({
          ...state,
          password: "",
          authenticated: true,
        });
      } else {
        setState({
          ...state,
          authenticated: false,
          errors: [
            {
              type: "error",
              message:
                "Ooops! There’s something wrong with your email address or password",
            },
          ],
        });
      }
    }
  }, [result.data]); // eslint-disable-line

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    if (state.email) {
      setState({
        ...state,
        login: false,
        next: true,
      });
    }
    if (state.email && state.password) {
      tokenAuth({
        variables: { email: state.email, password: state.password },
      });
    }
  };

  const handleChangeEmail = (email: string) => {
    setState({
      ...state,
      email: email.toLowerCase(),
    });
  };

  const handleChangePassword = (password: string) => {
    setState({ ...state, password: password });
  };

  return !isAuthenticated() ? (
    <form>
      <FormHeader
        onBackLinkClick={() => {
          navigate("/");
        }}
      />
      <EmailEntry onChange={handleChangeEmail} active={state.next} />
      <PasswordEntry
        onChange={handleChangePassword}
        errors={state.errors}
        active={state.next}
      />
      <TextPasswordReset />
      <ActionButton
        onClick={handleClick}
        title={state.next ? "Log in" : "Next"}
      />
    </form>
  ) : (
    <Navigate to={locationState?.from ? locationState.from : ROUTES.ACTIVITY} />
  );
};

const LogIn = () => {
  return (
    <div className="login">
      <LogInForm />
    </div>
  );
};

export default LogIn;
