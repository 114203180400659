import React, { useCallback, useRef, useState } from "react";

// Assets
import { ReactComponent as CameraSVG } from "../../assets/svg/icons/camera.svg";

// Third-party packages
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type ImageInputProps = {
  imgURL: string;
  defaultImage: string;
  saving: boolean;
  editing: boolean;
  uploadImage: (e: any) => void;
  startEditing: () => void;
  stopEditing: () => void;
};

const ImageInput = ({
  imgURL,
  defaultImage,
  saving,
  editing,
  uploadImage,
  startEditing,
  stopEditing,
}: ImageInputProps) => {
  const [state, setState] = useState<{
    imgURL: string;
    newImgURL: string;
    file: File | null;
    filename: string;
  }>({
    imgURL: imgURL,
    newImgURL: "",
    file: null,
    filename: "",
  });
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    aspect: 1,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const makeClientCrop = async (crop: ReactCrop.Crop) => {
    if (imgRef.current !== null && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        imgRef.current,
        crop,
        state.filename
      );
      if (croppedImage !== undefined) {
        setState({ ...state, file: croppedImage });
      }
    }
  };

  const onImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropComplete = (crop: ReactCrop.Crop) => {
    makeClientCrop(crop);
  };

  const getCroppedImg = (
    image: HTMLImageElement,
    crop: ReactCrop.Crop,
    fileName: string
  ) => {
    if (
      crop.height === undefined ||
      crop.width === undefined ||
      crop.x === undefined ||
      crop.y === undefined
    ) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d")!;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise<File>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          // console.error("Canvas is empty");
          return;
        }
        resolve(new File([blob], fileName));
      }, "image/jpeg");
    });
  };

  return (
    <div className={`image-input${editing ? " editing" : ""}`}>
      <div className={`cropper${saving ? " saving" : ""}`}>
        <div className="crop-container">
          <ReactCrop
            src={state.newImgURL || state.imgURL}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={(newCrop) => {
              setCrop(newCrop);
            }}
          />
        </div>
        <div className="crop-controls">
          <div className="form-button">
            <button
              className="btn btn-cancel"
              onClick={(e) => {
                e.preventDefault();
                stopEditing();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-save"
              onClick={(e) => {
                e.preventDefault();
                if (state.file) {
                  uploadImage({ variables: { file: state.file } });
                }
              }}
            >
              <CameraSVG />
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="controls">
        <img
          src={imgURL || defaultImage}
          onClick={(e) => {
            startEditing();
            fileInputRef.current?.click();
          }}
          alt=""
        />
        <input
          type="file"
          name="uploadPicture"
          accept="image/*"
          ref={fileInputRef}
          onChange={({ target: { validity, files } }) => {
            if (validity.valid && files && files.length > 0) {
              const reader = new FileReader();
              reader.addEventListener("load", () => {
                if (reader.result !== null)
                  setState({
                    ...state,
                    newImgURL: reader.result as string,
                    file: files[0],
                    filename: files[0].name,
                  });
              });
              reader.readAsDataURL(files[0]);
            } else {
              // TODO: Review cancellation logic.
              stopEditing();
            }
          }}
        />
        <label
          htmlFor="uploadPicture"
          onClick={(e) => {
            startEditing();
            fileInputRef.current?.click();
          }}
        >
          {imgURL ? "Change picture" : "Upload a picture"}
        </label>
      </div>
    </div>
  );
};

export default ImageInput;
