import React from "react";

type FormInputProps = {
  title?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  autoComplete?: string;
  error?: boolean;
  hidden?: boolean;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FormTextInput = ({
  title,
  placeholder,
  name,
  value,
  autoComplete,
  hidden,
  error,
  required,
  onChange,
}: FormInputProps) => (
  <div
    className={`form-input field${hidden ? " hidden" : ""}${
      error ? " invalid" : ""
    }`}
  >
    {title && <div className="title f-b">{title}</div>}
    <div className="field">
      <input
        type="text"
        name={name}
        autoComplete={autoComplete}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={(e) => {
          if (required && value === "") {
            e.target.classList.add("error");
          } else {
            e.target.classList.remove("error");
          }
        }}
      />
    </div>
  </div>
);

export const FormPasswordInput = ({
  title,
  placeholder,
  error,
  onChange,
}: FormInputProps) => (
  <div className={`form-input field${error ? " invalid" : ""}`}>
    {title && <div className="title f-b">{title}</div>}
    <div className="field">
      <input
        type="password"
        autoComplete="new-password"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  </div>
);
