import React, { useState } from "react";
import { Link } from "react-router-dom";

// Components
import AuthButton from "./AuthButton";
import SignUp from "./SignUp";
import { LogoHeader } from "../core/Brand";

// Styles
import "./core.sass";

const LeadCopy = () => (
  <div className="lead-copy f-b">
    Get connected with a community
    <br /> that makes you tick
  </div>
);

const TextLogIn = () => (
  <div className="text-footnote">
    Already have an account? <Link to={{ pathname: "/login" }}>Log in</Link>
  </div>
);

const TermsAndConditions = () => (
  <small>
    By signing up, you are confirming you agree to our{" "}
    <a
      href="https://www.economyofhours.com/terms-and-conditions"
      target="_blank"
      rel="noreferrer"
    >
      terms and conditions
    </a>
    .
  </small>
);

enum modes {
  START,
  SIGNUP,
}

export const SignUpView = () => {
  const [state, setState] = useState({ mode: modes.START });
  const handleEmailClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setState({ ...state, mode: modes.SIGNUP });
  };
  return (
    <div className="auth contained">
      {state.mode ===  modes.START ? (
        <div className="start-mode">
          <React.Fragment>
            <LogoHeader />
            <LeadCopy />
            <AuthButton
              onClick={handleEmailClick}
              title={"Sign up with email"}
            />
            <TextLogIn />
            <TermsAndConditions />
          </React.Fragment>
        </div>
      ) : (
        <SignUp />
      )}
    </div>
  );
};

export default SignUpView;
