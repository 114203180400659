import React, { useEffect } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// Types
import { LocationStateType, UserType } from "../../types";

// Styles
import "./Profile.sass";

// Components
import CancelButton from "../core/CancelButton";
import ActionButton from "../core/ActionButton";
import ProfileEdit from "./ProfileEdit";
import OfferList from "./OfferList";
import { SkillEntryList } from "./SkillList";

// Assets
import messageSVGURL from "../../assets/svg/icons/button/message-plain.svg";
import { ReactComponent as AddSVG } from "../../assets/svg/icons/button/add_24px.svg";
import { ReactComponent as PlusSVG } from "../../assets/svg/brand/plus.svg";
import { ReactComponent as HeartSVG } from "../../assets/svg/icons/heart.svg";
import { ReactComponent as PencilSVG } from "../../assets/svg/icons/pencil.svg";
import { ReactComponent as Community1SVG } from "../../assets/svg/brand/Community 1.svg";

// Apollo
import { useMutation, useQuery } from "@apollo/client/react";

// Queries
import { USER_PROFILE_QUERY } from "./queries";
import { ME_QUERY, REVOKE_TOKEN_MUTATION } from "../accounts/queries";

// Constants
import { REFRESH_TOKEN, ROUTES } from "../../constants";
import NoteList from "./NoteList";
import OfferDetails from "./Offer";
import OfferEdit from "./OfferEdit";
import {
  CREATE_DIALOGUE_MUTATION,
  DIALOGUES_QUERY,
} from "../conversation/queries";
import { setError } from "../core/utils";
import OfferDelete from "./OfferDelete";

type SimpleHeaderProps = {
  user: UserType;
  prevPath: string;
};

const SimpleHeader = ({ user, prevPath }: SimpleHeaderProps) => {
  const editLink = user.isCurrentUser ? (
    <Link to={`${ROUTES.PROFILE}/${user.id}/edit`} className="edit">
      <PencilSVG /> Edit info
    </Link>
  ) : (
    ""
  );
  return (
    <div className="header simple">
      <CancelButton pathname={prevPath} />
      <div className="edit-link">{editLink}</div>
    </div>
  );
};

type OffersProps = {
  user: UserType;
};

const Offers = ({ user }: OffersProps) => {
  const editLink = user.isCurrentUser ? (
    <div className="edit-link">
      <Link to={`${ROUTES.OFFER}/new`} className="edit">
        <AddSVG /> Add your offer
      </Link>
    </div>
  ) : (
    ""
  );
  const offers = user.profile?.offers ? (
    <OfferList user={user} />
  ) : (
    <div className="offers-empty">
      <Community1SVG className="offers-image" />
      <div className="info">
        <h4>Add your offer</h4>
        <p>
          Let the Echo community know how you can help by posting an offer. Echo
          members can discover your offer and get in touch with you.
        </p>
        <Link to={`${ROUTES.OFFER}/new`} className="btn edit f-b">
          Let’s write your offer
        </Link>
      </div>
    </div>
  );
  return (
    <div className="offers">
      <div className="offers-heading">
        <h6 className="f-b">Offers</h6>
        {editLink}
      </div>
      {offers}
    </div>
  );
};

type NotesProps = {
  user: UserType;
};

const Notes = ({ user }: NotesProps) => {
  const notes = user.profile?.offers ? <NoteList user={user} /> : "";
  return (
    <div className="notes">
      <div className="notes-heading">
        <h6 className="f-b">Thank yous</h6>
      </div>
      {notes}
    </div>
  );
};

const Profile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { client, loading, error, data } = useQuery(USER_PROFILE_QUERY, {
    variables: { id: userId },
  });

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`${ROUTES.MESSAGES}/${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  const [revokeToken, resultRevokeToken] = useMutation(REVOKE_TOKEN_MUTATION);

  useEffect(() => {
    if (resultRevokeToken.data) {
      if (resultRevokeToken.data.revokeToken.success) {
        client.resetStore();
        localStorage.clear();
        navigate("/");
        window.location.reload();
      }
    }
  }, [resultRevokeToken.data, client, navigate]);

  if (loading || error) return null;

  const user = data.user;

  const controls = user.isCurrentUser ? (
    <div className="controls">
      <ActionButton
        type="btn-logout"
        title="Log out"
        onClick={() => {
          revokeToken({
            variables: { refreshToken: localStorage.getItem(REFRESH_TOKEN) },
          });
        }}
      />
    </div>
  ) : (
    <div className="controls">
      <ActionButton
        type="btn-message"
        iconURL={messageSVGURL}
        title="Message"
        onClick={() => {
          createDialogue({ variables: { userId: userId } });
        }}
      />
    </div>
  );

  const editLink = user.isCurrentUser ? (
    <div className="edit-link">
      <Link to={`${ROUTES.PROFILE}/${user.id}/edit/skills`} className="edit">
        <PencilSVG /> Edit your skills
      </Link>
    </div>
  ) : (
    ""
  );

  const timeComp = user.isCurrentUser ? (
    <Link to={`${ROUTES.PROFILE}/${user.id}/edit/time`} className="edit">
      <span className="time">
        <PlusSVG />
        Available {user.profile?.time} hr
        {user.profile?.time && user.profile?.time === 1 ? "" : "s"} this month
      </span>
    </Link>
  ) : (
    <span className="time">
      <PlusSVG />
      Available {user.profile?.time} hr
      {user.profile?.time && user.profile?.time === 1 ? "" : "s"} this month
    </span>
  );
  const timeTaken =
    user.profile?.taken !== 1
      ? `${user.profile?.taken} hours given`
      : `${user.profile?.taken} hour given`;
  return (
    <div className="profile full cntrlse">
      <SimpleHeader
        prevPath={(state as LocationStateType)?.prevPath || "/activity"}
        user={user}
      />
      <div className="user-info">
        <div className="image">
          {user.profile?.picture && (
            <img src={user.profile?.picture} alt={user.name} />
          )}
        </div>
        <div className="name f-b">{user.profile?.preferredName}</div>
        <div className="project f-b">{user.profile?.project}</div>
        <div className="bio">{user.profile?.bio}</div>
        <div className="meta">
          {timeComp}
          <span className="taken">
            <HeartSVG />
            {timeTaken}
          </span>
        </div>
      </div>
      {controls}
      <div className="skill-list">
        <div className="skill-list-heading">
          <h6 className="f-b">Skills</h6>
          {editLink}
        </div>
        <SkillEntryList skills={user.profile?.skills || []} />
      </div>
      <Offers user={user} />
      <Notes user={user} />
    </div>
  );
};

const RedirectProfile = () => {
  const { loading, error, data } = useQuery(ME_QUERY, {});
  if (loading || error) return null;
  return <Navigate to={`${ROUTES.PROFILE}/${data.me.id}`} />;
};

const ProfileView = () => {
  return (
    <Routes>
      <Route index element={<RedirectProfile />} />
      <Route path={`:userId/:offerId/edit`} element={<OfferEdit />} />
      <Route path={`:userId/:offerId/delete`} element={<OfferDelete />} />
      <Route path={`:userId/edit`} element={<ProfileEdit />} />
      <Route path={`:userId/edit/:mode`} element={<ProfileEdit />} />
      <Route path={`:userId/:offerId`} element={<OfferDetails />} />
      <Route path={`:userId`} element={<Profile />} />
    </Routes>
  );
};

export default ProfileView;
