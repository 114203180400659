import React from "react";
import { Route, Routes } from "react-router-dom";

import OfferEdit from "./OfferEdit";

const OfferView = () => {
  return (
    <Routes>
      <Route path={`new`} element={<OfferEdit />} />
    </Routes>
  );
};

export default OfferView;
