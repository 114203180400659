import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";

// Components
import FormHeader from "./FormHeader";
import ActionButton from "../core/ActionButton";
import Selector from "../core/Selector";
import { FormTitle } from "../core/Forms";
import SingleSelector from "../core/SingleSelector";
import { Toast } from "../core/Toast";
import ImageInput from "../core/ImageInput";
import { FormTextInput, FormPasswordInput } from "./Inputs";

// Configuration
import { timeOptions, Category, ROUTES } from "../../constants";

// Types
import { CommunityType, ErrorType, SkillType } from "../../types";

// Queries
import {
  COMMUNITIES_QUERY,
  REGISTER_MUTATION,
  UPDATE_USER_COMMUNITIES_MUTATION,
  UPDATE_USER_LOCATION_MUTATION,
} from "./queries";

import {
  SKILLS_QUERY,
  UPDATE_USER_PROFILE_FULL_MUTATION,
  UPDATE_USER_PROFILE_PICTURE_MUTATION,
} from "../people/queries";

// Assets
import starSVGURL from "../../assets/svg/icons/star.svg";
import starNoSVGURL from "../../assets/svg/icons/star-no.svg";
import profileImageSVGURL from "../../assets/svg/icons/profile-image.svg";

// Core logic
import { isEmailAddress } from "../core/utils";
import { saveUser } from "./core";

enum Step {
  Email,
  Password,
  Profile,
  Skills,
  SortSkills,
  Time,
  Communities,
  Location,
  Complete,
}

const SignUp = () => {
  const { data: dataSkills } = useQuery(SKILLS_QUERY);

  const { data: dataCommunities } = useQuery(COMMUNITIES_QUERY);

  const [coreState, setCoreState] = useState({
    skills: [],
    communities: [],
  });

  const [formState, setFormState] = useState({
    step: Step.Email,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    bio: "",
    picture: "",
    savingImage: false,
    editingImage: false,
    project: "",
    time: 1,
    skills: [] as string[],
    starSkills: [] as string[],
    communities: [] as string[],
    location: "",
    errors: [] as ErrorType[],
  });

  const [register, resultRegister] = useMutation(REGISTER_MUTATION);

  const navigate = useNavigate();

  useEffect(() => {
    if (dataSkills) {
      setCoreState({
        ...coreState,
        skills: dataSkills.skills,
      });
    }
  }, [dataSkills, coreState]);

  useEffect(() => {
    if (dataCommunities) {
      setCoreState({
        ...coreState,
        communities: dataCommunities.communities.map(
          (s: CommunityType) => s.name
        ),
      });
    }
  }, [dataCommunities, coreState]);

  useEffect(() => {
    if (resultRegister.data) {
      const token = resultRegister.data.register.token;
      if (token) {
        saveUser(
          { username: formState.email },
          resultRegister.data.register.token,
          resultRegister.data.register.refreshToken
        );
        setFormState({
          ...formState,
          errors: [],
          step: Step.Profile,
        });
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message:
                "There was an issue creating your account. Please check and try again.",
            },
          ],
        });
      }
    }
  }, [resultRegister.data, formState]);

  const [updateUserProfile, resultUpdateUserProfile] = useMutation(
    UPDATE_USER_PROFILE_FULL_MUTATION,
    {
      variables: {
        firstName: formState.firstName,
        lastName: formState.lastName,
        bio: formState.bio,
        project: formState.project,
        skills: formState.skills,
        starSkills: formState.starSkills,
        time: formState.time,
      },
    }
  );

  useEffect(() => {
    if (resultUpdateUserProfile.data) {
    }
  }, [resultUpdateUserProfile.data]);

  const [updateUserCommunities, resultUpdateUserCommunities] = useMutation(
    UPDATE_USER_COMMUNITIES_MUTATION,
    {
      variables: {
        communities: formState.communities,
      },
    }
  );

  useEffect(() => {
    if (resultUpdateUserCommunities.data) {
    }
  }, [resultUpdateUserCommunities.data]);

  const [updateUserLocation] = useMutation(
    UPDATE_USER_LOCATION_MUTATION,
    {
      variables: {
        location: formState.location,
      },
    }
  );

  useEffect(() => {
    if (resultUpdateUserCommunities.data) {
    }
  }, [resultUpdateUserCommunities.data]);

  const [updateUserProfilePicture, resultPicture] = useMutation(
    UPDATE_USER_PROFILE_PICTURE_MUTATION
  );

  useEffect(() => {
    if (resultPicture.data) {
      const picture =
        resultPicture.data.updateUserProfilePicture.user.profile?.picture;
      setFormState({
        ...formState,
        picture: picture,
        savingImage: false,
        editingImage: false,
      });
    }
  }, [resultPicture.data, formState]);

  const validateEmail = () => {
    return isEmailAddress(formState.email);
  };

  const validatePassword = () => {
    if (formState.password !== "") {
      return true;
    }
    return false;
  };

  const validateProfile = () => {
    if (
      formState.firstName !== "" &&
      formState.lastName !== "" &&
      formState.bio !== ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="signup">
      <form>
        {formState.step === Step.Email && (
          <div className="email">
            <FormHeader
              onBackLinkClick={() => {
                navigate(ROUTES.LOGIN);
              }}
            />
            <FormTitle title={"Sign up"} />
            <FormTextInput
              title="Email"
              placeholder="Enter your email"
              value={formState.email}
              required={true}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: e.target.value.toLowerCase(),
                })
              }
              error={formState.errors.length > 0}
            />
            {formState.errors.length !== 0 && (
              <React.Fragment>
                <div className="error">{formState.errors[0].message}</div>
                <Toast
                  show={true}
                  className="error"
                  message="Something wasn’t right"
                />
              </React.Fragment>
            )}
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validateEmail()) {
                  setFormState({
                    ...formState,
                    step: Step.Password,
                    errors: [],
                  });
                } else {
                  setFormState({
                    ...formState,
                    errors: [
                      { type: "", message: "You need a valid email address" },
                    ],
                  });
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Password && (
          <div className="password">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.Email,
                })
              }
            />
            <FormTitle title={"Set a password"} />
            <p>
              We need your password to be at least 8 characters long, with at
              least one number or special character.
            </p>
            <FormTextInput
              name="username"
              autoComplete="username"
              hidden={true}
              value={formState.email}
              required={true}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: e.target.value,
                })
              }
            />
            <FormPasswordInput
              title="Password"
              placeholder="Secure and memorable is a great choice"
              required={true}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: e.target.value,
                })
              }
              error={formState.errors.length > 0}
            />
            {formState.errors.length !== 0 && (
              <React.Fragment>
                <div className="error">{formState.errors[0].message}</div>
                <Toast
                  show={true}
                  className="error"
                  message="Something wasn’t right"
                />
              </React.Fragment>
            )}
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validatePassword()) {
                  register({
                    variables: {
                      email: formState.email,
                      password: formState.password,
                    },
                  });
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Profile && (
          <div className="profile">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.Password,
                })
              }
            />
            <FormTitle title={"Let's get to know you"} />
            <FormTextInput
              title="First name"
              placeholder="Adam"
              value={formState.firstName}
              required={true}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  firstName: e.target.value,
                })
              }
            />
            <FormTextInput
              title="Last name"
              placeholder="Grant"
              value={formState.lastName}
              required={true}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  lastName: e.target.value,
                })
              }
            />
            <FormTextInput
              title="Write a personal and professional tag line"
              placeholder="I am…"
              value={formState.bio}
              required={true}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  bio: e.target.value,
                })
              }
            />

            <div className="form-input field">
              <div className="title f-b">Choose a profile picture</div>
              <div className="field image">
                <small>
                  This helps people get to know you, and recognise you if you
                  meet up. Please keep images (JPG/PNG) less than 5 MB in size
                </small>
                <ImageInput
                  imgURL={formState.picture}
                  defaultImage={profileImageSVGURL}
                  saving={formState.savingImage}
                  editing={formState.editingImage}
                  uploadImage={(params) => {
                    setFormState({ ...formState, savingImage: true });
                    updateUserProfilePicture(params);
                  }}
                  startEditing={() => {
                    setFormState({ ...formState, editingImage: true });
                  }}
                  stopEditing={() => {
                    setFormState({ ...formState, editingImage: false });
                  }}
                />
              </div>
            </div>
            <FormTextInput
              title="Business or project"
              placeholder="XYZ Redesign"
              value={formState.project}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  project: e.target.value,
                })
              }
            />
            {formState.errors.length !== 0 && (
              <React.Fragment>
                <div className="error">{formState.errors[0].message}</div>
                <Toast
                  show={true}
                  className="error"
                  message="Something wasn’t right"
                />
              </React.Fragment>
            )}
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validateProfile()) {
                  setFormState({
                    ...formState,
                    step: Step.Skills,
                  });
                } else {
                  setFormState({
                    ...formState,
                    errors: [
                      { type: "", message: "Please complete your profile" },
                    ],
                  });
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Skills && (
          <div className="skills">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.Profile,
                })
              }
            />
            <FormTitle title={"How can you help?"} />
            <p>
              Don’t be shy about skills, really basic knowledge is still helpful
              to some Echo members. Select the areas where you have some
              knowledge or skills so that other Echo members know how you can
              help.
            </p>
            <h4>Do you have any knowledge in?</h4>
            <div className="fields">
              <div className="field">
                <label htmlFor="skills">
                  <h6>Help and advice</h6>
                </label>
                <Selector
                  options={coreState.skills
                    .filter(
                      (s: SkillType) => s.category === Category.HelpAndAdvice
                    )
                    .map((s: SkillType) => s.title)}
                  selected={formState.skills}
                  onSelect={(v: string[]) => {
                    setFormState({
                      ...formState,
                      skills: v,
                    });
                  }}
                />
              </div>
              <div className="field">
                <label htmlFor="skills">
                  <h6>Business</h6>
                </label>
                <Selector
                  options={coreState.skills
                    .filter((s: SkillType) => s.category === Category.Business)
                    .map((s: SkillType) => s.title)}
                  selected={formState.skills}
                  onSelect={(v: string[]) => {
                    setFormState({
                      ...formState,
                      skills: v,
                    });
                  }}
                />
              </div>
              <div className="field">
                <label htmlFor="skills">
                  <h6>Digital and creative</h6>
                </label>
                <Selector
                  options={coreState.skills
                    .filter(
                      (s: SkillType) =>
                        s.category === Category.DigitalAndCreative
                    )
                    .map((s: SkillType) => s.title)}
                  selected={formState.skills}
                  onSelect={(v: string[]) => {
                    setFormState({
                      ...formState,
                      skills: v,
                    });
                  }}
                />
              </div>
              <div className="field">
                <label htmlFor="skills">
                  <h6>Learning, wellbeing and skillshare</h6>
                </label>
                <Selector
                  options={coreState.skills
                    .filter(
                      (s: SkillType) =>
                        s.category === Category.LearningWellbeingAndSkillshare
                    )
                    .map((s: SkillType) => s.title)}
                  selected={formState.skills}
                  onSelect={(v: string[]) => {
                    setFormState({
                      ...formState,
                      skills: v,
                    });
                  }}
                />
              </div>
              <div className="field">
                <label htmlFor="skills">
                  <h6>Space</h6>
                </label>
                <Selector
                  options={coreState.skills
                    .filter((s: SkillType) => s.category === Category.Space)
                    .map((s: SkillType) => s.title)}
                  selected={formState.skills}
                  onSelect={(v: string[]) => {
                    setFormState({
                      ...formState,
                      skills: v,
                    });
                  }}
                />
              </div>
            </div>
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                // TODO: Update validation function
                if (validatePassword()) {
                  setFormState({
                    ...formState,
                    step: Step.SortSkills,
                  });
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.SortSkills && (
          <div className="sort-skills">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.Skills,
                })
              }
            />
            <FormTitle title={"Star what you are most confident in"} />
            <p>
              We’ll try and connect you with people who need help or advice in
              these areas. You’ll get to choose whether you’re able to help them
              before we make any introductions.
            </p>
            <div className="field">
              <label htmlFor="sort-skills">
                <h6>Select</h6>
              </label>
              <Selector
                className="sort-skills"
                selectedIcon={starSVGURL}
                deselectedIcon={starNoSVGURL}
                options={formState.skills}
                selected={formState.starSkills}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    starSkills: v,
                  });
                }}
              />
            </div>
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validatePassword()) {
                  setFormState({
                    ...formState,
                    step: Step.Time,
                  });
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Time && (
          <div className="time">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.SortSkills,
                })
              }
            />
            <FormTitle title={"How much time can you help each month?"} />
            <p>
              We’ll connect you with people who need your help based on how much
              time you have available per month.
            </p>
            <div className="field">
              <SingleSelector
                className="sort-skills"
                options={timeOptions}
                selected={`${formState.time}`}
                onSelect={(v: string | number) => {
                  setFormState({
                    ...formState,
                    time: parseInt(`${v}`),
                  });
                }}
              />
            </div>
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validatePassword()) {
                  setFormState({
                    ...formState,
                    step: Step.Communities,
                  });
                  updateUserProfile();
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Communities && (
          <div className="time">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.Time,
                })
              }
            />
            <FormTitle title={"Join a community"} />
            <p>
              By default you’ll be part of the Echo community. You can also join
              location based communities
            </p>
            <div className="field">
              <Selector
                options={coreState.communities}
                selected={formState.communities}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    communities: v,
                  });
                }}
              />
            </div>
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validatePassword()) {
                  setFormState({
                    ...formState,
                    step: Step.Location,
                  });
                  updateUserCommunities();
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Location && (
          <div className="time">
            <FormHeader
              onBackLinkClick={() =>
                setFormState({
                  ...formState,
                  step: Step.Time,
                })
              }
            />
            <FormTitle title={"Where are you?"} />
            <p>
              We can enhance your experience of Echo with more relevant content
              based on your location
            </p>
            <div className="field">
              <FormTextInput
                title="Postcode or city"
                placeholder="London"
                value={formState.location}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    location: e.target.value,
                  })
                }
              />
            </div>
            <ActionButton
              type="btn-next"
              title="Next"
              onClick={(e) => {
                e.preventDefault();
                if (validatePassword()) {
                  setFormState({
                    ...formState,
                    step: Step.Complete,
                  });
                  updateUserLocation();
                }
              }}
            />
          </div>
        )}
        {formState.step === Step.Complete && (
          <div className="complete">
            <h4>Great, you’re set up. Welcome to the Echo community!</h4>
            <Link
              className="auth-button f-b"
              to={{ pathname: ROUTES.ACTIVITY }}
            >
              Start exploring
            </Link>
          </div>
        )}
        {formState.step !== Step.Complete && (
          <div className="step-tracker">
            <div className="step-wrap">
              <div
                className={
                  "step" + (formState.step === Step.Email ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.Password ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.Profile ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.Skills ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.SortSkills ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.Time ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.Communities ? " active" : "")
                }
              ></div>
              <div
                className={
                  "step" + (formState.step === Step.Location ? " active" : "")
                }
              ></div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default SignUp;
