import * as React from "react";
import * as ReactDOM from "react-dom";

import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";

// Load Apollo packages
import { ApolloProvider } from "@apollo/client";

// Create Apollo client
import ApolloClient from "./client/apolloClient";

// Load initial React views
import SignUpView from "./components/accounts/SignUpView";
import LoginView from "./components/accounts/LoginView";
import MessagingView from "./components/conversation/MessagingView";
import PeopleView from "./components/people/PeopleView";
import ProfileView from "./components/people/Profile";
import OfferView from "./components/people/OfferView";
import ActivityView from "./components/activity/ActivityView";

// TODO: Update authentication code
import { isAuthenticated, RequireAuth } from "./components/accounts/core";

// Notification Provider
import { NotificationProvider } from "./components/core/Notification";

// Constants
import { ROUTES } from "./constants";

import "./sass/base.sass";
import PasswordReset from "./components/accounts/PasswordReset";
import Activate from "./components/accounts/Activate";
import SendPasswordResetEmail from "./components/accounts/SendPasswordResetEmail";

const init = () => {
  const client = ApolloClient;
  ReactDOM.render(
    <ApolloProvider client={client}>
      <NotificationProvider>
        <Router>
          <Routes>
            <Route
              path={ROUTES.SIGNUP}
              element={
                isAuthenticated() ? (
                  <Navigate to={ROUTES.ACTIVITY} replace />
                ) : (
                  <SignUpView />
                )
              }
            />
            <Route
              path={ROUTES.LOGIN}
              element={
                isAuthenticated() ? (
                  <Navigate to={ROUTES.ACTIVITY} />
                ) : (
                  <LoginView />
                )
              }
            />
            <Route path={ROUTES.PASSWORD_RESET}>
              <Route index element={<SendPasswordResetEmail />} />
              <Route index={false} path=":token" element={<PasswordReset />} />
            </Route>
            <Route path={ROUTES.ACTIVATE}>
              <Route index={false} path=":token" element={<Activate />} />
            </Route>
            <Route
              path={ROUTES.ACTIVITY}
              element={
                <RequireAuth>
                  <ActivityView />
                </RequireAuth>
              }
            ></Route>
            <Route
              path={`${ROUTES.MESSAGES}/*`}
              element={
                <RequireAuth>
                  <MessagingView />
                </RequireAuth>
              }
            ></Route>
            <Route
              path={`${ROUTES.OFFER}/*`}
              element={
                <RequireAuth>
                  <OfferView />
                </RequireAuth>
              }
            ></Route>
            <Route
              path={ROUTES.PEOPLE}
              element={
                <RequireAuth>
                  <PeopleView />
                </RequireAuth>
              }
            ></Route>
            <Route
              path={`${ROUTES.PROFILE}/*`}
              element={
                <RequireAuth>
                  <ProfileView />
                </RequireAuth>
              }
            ></Route>
            <Route
              path={`/`}
              element={
                isAuthenticated() ? (
                  <Navigate to={ROUTES.ACTIVITY} replace />
                ) : (
                  <Navigate to={ROUTES.LOGIN} replace />
                )
              }
            />
          </Routes>
        </Router>
      </NotificationProvider>
    </ApolloProvider>,
    document.getElementById("app")
  );
};

init();
