import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";

// Components
import FormHeader from "./FormHeader";
import ActionButton from "../core/ActionButton";
import { FormTitle } from "../core/Forms";
import { Toast } from "../core/Toast";
import { FormPasswordInput } from "./Inputs";
import { TextSignUp } from "./core";

// Configuration
import { ROUTES } from "../../constants";

// Queries
import { PASSWORD_RESET_MUTATION } from "./queries";

// Assets
import { ErrorType } from "../../types";

enum Step {
  Start,
  Complete,
}

const PasswordReset = () => {
  const [formState, setFormState] = useState({
    newPassword1: "",
    newPassword2: "",
    errors: [] as ErrorType[],
    step: Step.Start,
  });

  const [passwordReset, resultPasswordReset] = useMutation(
    PASSWORD_RESET_MUTATION
  );

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (resultPasswordReset.data) {
      const success = resultPasswordReset.data.passwordReset.success;
      if (success) {
        setFormState({
          ...formState,
          errors: [],
          step: Step.Complete,
        });
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message:
                "There was an issue resetting your password. Please check and try again.",
            },
          ],
        });
      }
    }
  }, [resultPasswordReset.data, formState]);

  const validatePassword = () => {
    if (
      formState.newPassword1 !== "" &&
      formState.newPassword1 === formState.newPassword2
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="auth contained">
      <div className="reset-mode">
        <div className="password-reset">
          {token && (
            <form>
              {formState.step === Step.Start && (
                <div className="password">
                  <FormHeader
                    onBackLinkClick={() => {
                      navigate(ROUTES.LOGIN);
                    }}
                  />
                  <FormTitle title={"Reset"} />
                  <p>
                    We need your password to be at least 8 characters long, with
                    at least one number or special character.
                  </p>
                  <FormPasswordInput
                    placeholder="Enter your new password"
                    value={formState.newPassword1}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        newPassword1: e.target.value,
                      })
                    }
                    error={formState.errors.length > 0}
                  />
                  <FormPasswordInput
                    placeholder="Confirm your new password"
                    value={formState.newPassword2}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        newPassword2: e.target.value,
                      })
                    }
                    error={formState.errors.length > 0}
                  />
                  {formState.errors.length !== 0 && (
                    <React.Fragment>
                      <div className="error">{formState.errors[0].message}</div>
                      <Toast
                        show={true}
                        className="error"
                        message="Something wasn’t right"
                      />
                    </React.Fragment>
                  )}
                  <ActionButton
                    type="btn-next"
                    title="Next"
                    onClick={(e) => {
                      e.preventDefault();
                      if (validatePassword()) {
                        passwordReset({
                          variables: {
                            token: token,
                            newPassword1: formState.newPassword1,
                            newPassword2: formState.newPassword2,
                          },
                        });
                      }
                    }}
                  />
                </div>
              )}
              {formState.step === Step.Complete && (
                <div className="complete">
                  <FormHeader
                    onBackLinkClick={() => {
                      navigate(ROUTES.LOGIN);
                    }}
                  />
                  <FormTitle title={"Reset"} />
                  <h4>Great, you’re done. Try logging in!</h4>
                  <div className="wrap-button action form-button">
                    <Link
                      className="auth-button f-b"
                      to={{ pathname: ROUTES.LOGIN }}
                    >
                      Log in
                    </Link>
                  </div>
                </div>
              )}
            </form>
          )}
        </div>
        <TextSignUp />
      </div>
    </div>
  );
};

export default PasswordReset;
