import React from "react";
import { useNavigate } from "react-router-dom";

import cancelSVGURL from "../../assets/svg/icons/button/cancel.svg";

import "./CancelLink.sass";

type CancelLinkProps = {
  pathname?: string;
  label?: string;
};

const CancelLink = ({ pathname, label = "Cancel" }: CancelLinkProps) => {
  const navigate = useNavigate();
  return (
    <span className="wrap-link back cancel">
      <button
        onClick={() => {
          pathname ? navigate(pathname) : navigate(-1);
        }}
        className="btn"
      >
        <img src={cancelSVGURL} alt={label} />
      </button>
    </span>
  );
};

export default CancelLink;
