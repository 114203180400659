import React from "react";

import ActionItem from "./ActionItem";

import { ActionType } from "../../types";

// Styles
import "./ActionList.sass";

type ActionListProps = {
  actions?: ActionType[];
  more: boolean;
  onLoadMore: any;
};

const ActionList = ({ actions, more, onLoadMore }: ActionListProps) => {
  return (
    <div className="action-list">
      <div className="actions">
        <div className="items">
          {actions?.map(
            (action: ActionType, index: string | number | undefined) => (
              <ActionItem action={action} key={index} />
            )
          )}
        </div>
        {more && (
          <button className="btn action" onClick={onLoadMore}>
            Load more
          </button>
        )}
      </div>
    </div>
  );
};

export default ActionList;
