import * as React from "react";
import { Link } from "react-router-dom";
import { useLocation, Navigate } from "react-router-dom";

import { REFRESH_TOKEN, ROUTES, TOKEN, USER_KEY } from "../../constants";

export const saveUser = (data: Object, token: string, refreshToken: string) => {
  localStorage.setItem(USER_KEY, JSON.stringify(data));
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getUser = () => {
  const user = localStorage.getItem(USER_KEY);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const deleteUser = () => {
  localStorage.removeItem(USER_KEY);
};

export const isAuthenticated = () => {
  return getUser() ? true : false;
};

type Props = {
  children: JSX.Element;
};

export const RequireAuth: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const auth = isAuthenticated();
  if (!auth) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }
  return children;
};

export const TextSignUp = () => (
  <div className="text-footnote">
    Remembered your password? <Link to={{ pathname: "/login" }}>Log in</Link>
  </div>
);
