import React, { useEffect, useState } from "react";

// Logic
import { useMutation, useQuery } from "@apollo/client/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Types
import { OfferType, UserType, LocationStateType } from "../../types";

// Styles
import "./Offer.sass";

// Components
import CancelButton from "../core/CancelButton";
import SimpleButton from "../core/SimpleButton";

// Assets
import { ReactComponent as CaseSVG } from "../../assets/svg/icons/case.svg";

// Queries
import { OFFER_QUERY, USER_PROFILE_SHORT_QUERY } from "./queries";

// Constants
import { DELETE_OFFER_MUTATION } from "../people/queries";

type SimpleHeaderProps = {
  user: UserType;
  offer: OfferType;
  prevPath: string;
};

const SimpleHeader = ({ prevPath }: SimpleHeaderProps) => {
  return (
    <div className="header simple">
      <CancelButton pathname={prevPath} />
    </div>
  );
};

type OfferProps = {
  user: UserType;
  offer: OfferType;
};

const OfferDeleteSimple = ({ user, offer }: OfferProps) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    deleting: false,
  });

  const [deleteOffer, result] = useMutation(DELETE_OFFER_MUTATION, {
    variables: { id: offer?.id },
  });

  useEffect(() => {
    if (result.data && result.data?.deleteOffer.status === 200) {
      setFormState({ ...formState, deleting: false });
      navigate(`/profile/${user.id}/`, {
        state: { prevPath: `/profile/${user.id}` },
      });
    }
  }, [result.data, formState, navigate, user.id]);

  return (
    <div className="offer delete contained">
      <SimpleHeader
        user={user}
        offer={offer}
        prevPath={(state as LocationStateType)?.prevPath}
      />
      <div className="user-info">
        <div className="visual">
          <div className="image">
            {user.profile?.picture && (
              <img src={user.profile?.picture} alt={user.name} />
            )}
          </div>
        </div>
        <div className="textual">
          <div className="details">
            <div className="name f-b">{user.profile?.preferredName}</div>
            {user.profile?.project && (
              <div className="project">
                <CaseSVG /> {user.profile?.project}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="title f-b">{offer.title}</div>
      <div className="controls">
        <SimpleButton
          title="Delete"
          onClick={(e) => {
            e.preventDefault();
            if (formState.deleting !== true) {
              setFormState({
                ...formState,
                deleting: true,
              });
              deleteOffer({ variables: { id: offer.id } });
            }
          }}
        />
      </div>
    </div>
  );
};

const OfferDelete = () => {
  const { userId, offerId } = useParams();
  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
  } = useQuery(USER_PROFILE_SHORT_QUERY, {
    variables: { id: userId },
  });
  const {
    loading: loadingOffer,
    error: errorOffer,
    data: dataOffer,
  } = useQuery(OFFER_QUERY, {
    variables: { id: offerId },
  });

  if (loadingUser || errorUser || loadingOffer || errorOffer) return null;

  return <OfferDeleteSimple user={dataUser.user} offer={dataOffer.offer} />;
};

export default OfferDelete;
