import React from "react";

import "./Selector.sass";

type SelectorProps = {
  options: string[];
  selected: string[];
  className?: string;
  selectedIcon?: string;
  deselectedIcon?: string;
  onSelect: (v: string[]) => void;
};

type IconImageProps = {
  imageURL?: string;
};

const IconImage = ({ imageURL }: IconImageProps) =>
  imageURL ? <img src={imageURL} alt="" /> : null;

const Selector = ({
  options,
  selected,
  className,
  selectedIcon,
  deselectedIcon,
  onSelect,
}: SelectorProps) => {
  return (
    <div className={`selector ${className}`}>
      {options.map((option, i) => (
        <span
          className={
            "option" + (selected.indexOf(option) === -1 ? "" : " selected")
          }
          onClick={(e) => {
            e.preventDefault();
            onSelect(
              selected.indexOf(option) === -1
                ? [...selected, option]
                : selected.filter((v) => v !== option)
            );
          }}
          key={i}
        >
          {selected.indexOf(option) === -1 ? (
            <IconImage imageURL={deselectedIcon} />
          ) : (
            <IconImage imageURL={selectedIcon} />
          )}
          {option}
        </span>
      ))}
    </div>
  );
};

export default Selector;
