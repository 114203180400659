import React from "react";
import { SkillEntryType, SkillType } from "../../types";

type SkillEntryItemProps = {
  skillEntry: SkillEntryType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SkillEntryItem = ({ skillEntry, onClick }: SkillEntryItemProps) => (
  <span
    className={`skill${skillEntry.offering ? " offering" : ""}`}
    onClick={onClick}
  >
    {skillEntry.skill.title}
  </span>
);

type SkillEntryListProps = {
  skills: SkillEntryType[];
};

export const SkillEntryList = ({ skills }: SkillEntryListProps) => (
  <div className={`skills ${skills && skills.length > 0 ? "" : "empty"}`}>
    {skills.map(
      (skillEntry: SkillEntryType, index: string | number | undefined) => (
        <SkillEntryItem
          skillEntry={skillEntry}
          key={index}
          onClick={() => null}
        />
      )
    )}
  </div>
);

type SkillItemProps = {
  skill: SkillType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SkillItem = ({ skill, onClick }: SkillItemProps) => (
  <span className="skill  offering" onClick={onClick}>
    {skill.title}
  </span>
);

type SkillListProps = {
  skills: SkillType[];
};

export const SkillList = ({ skills }: SkillListProps) => (
  <div className="skills">
    {skills.map((skill: SkillType, index: string | number | undefined) => (
      <SkillItem skill={skill} key={index} onClick={() => null} />
    ))}
  </div>
);
