import React from "react";

type ActionButtonProps = {
  title: string;
  type?: string;
  iconURL?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ActionButton = ({ title, type, iconURL, onClick }: ActionButtonProps) => {
  const icon = iconURL ? (
    <img className="image-btn" src={iconURL} alt={title} />
  ) : (
    ""
  );
  return (
    <div className="wrap-button action form-button">
      <button className={`action btn ${type}`} onClick={onClick}>
        {icon}
        {title}
      </button>
    </div>
  );
};

export default ActionButton;
