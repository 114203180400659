import React, { useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import "./Header.sass";

import Balance from "./Balance";

import logoSVGURL from "../../assets/svg/brand/logo.svg";

import { ME_QUERY } from "../accounts/queries";

import useNotification from "./Notification";

import { Type } from "../../types";

type HeaderProps = {
  title: string;
  balance?: string;
};

const Header = ({ title }: HeaderProps) => {
  const { loading, error, data, refetch } = useQuery(ME_QUERY);
  const location = useLocation();
  const { notifications } = useNotification();

  useEffect(() => {
    if (notifications.filter((n) => n.type === Type.TRADE).length) {
      // When a notification arrives, trigger a refetch of the user balance.
      refetch();
    }
  }, [notifications, refetch]);

  if (loading) return null;
  if (error) return null;

  const user = data.me;

  return (
    <React.Fragment>
      <header className="cntrlse">
        <div className="primary">
          <div className="logo">
            <img src={logoSVGURL} alt="logo" />
          </div>
          <Balance balance={parseInt(user.profile?.balance)} />
          <div className="image">
            <Link
              to={`/profile/${user.id}`}
              state={{ prevPath: location.pathname }}
            >
              {user.profile?.picture && (
                <img src={user.profile?.picture} alt={user.name} />
              )}
            </Link>
          </div>
        </div>
        <div className="header-main">
          <span className="title">{title}</span>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
