import React from "react";

import "./SingleSelector.sass";

type SingleSelectorProps = {
  options: { label: string; value: string | number }[];
  selected: string | number;
  className?: string;
  selectedIcon?: string;
  deselectedIcon?: string;
  onSelect: (v: string | number) => void;
};

type IconImageProps = {
  imageURL?: string;
};

const IconImage = ({ imageURL }: IconImageProps) =>
  imageURL ? <img src={imageURL} alt="" /> : null;

const SingleSelector = ({
  options,
  selected,
  className,
  selectedIcon,
  deselectedIcon,
  onSelect,
}: SingleSelectorProps) => {
  return (
    <div className={`single-selector ${className}`}>
      {options.map((option, i) => (
        <span
          className={"option" + (selected === option.value ? " selected" : "")}
          onClick={(e) => {
            e.preventDefault();
            onSelect(selected !== option.value ? option.value : "");
          }}
          key={i}
        >
          {selected === option.value ? (
            <IconImage imageURL={deselectedIcon} />
          ) : (
            <IconImage imageURL={selectedIcon} />
          )}
          {option.label}
        </span>
      ))}
    </div>
  );
};

export default SingleSelector;
