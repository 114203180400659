// React
import React, { useEffect, useState } from "react";

/// Apollo
import { useQuery } from "@apollo/client";

// Components
import Navigation from "../core/Navigation";
import Header from "../core/Header";
import DesktopHeader from "../core/DesktopHeader";
import Search from "../core/Search";

import CreateOffer from "../widgets/CreateOffer";
import Greeting from "../widgets/Greeting";

// Types
import { ActionTypeEdge, CommunityType } from "../../types";

// Queries
import { COMMUNITIES_QUERY } from "../accounts/queries";
import { ACTIONS_QUERY } from "./queries";

// Assets
import ActionList from "./ActionList";

// Constants
import { FilterMode, DefaultPaginationLimit } from "../../constants";
import { Banner } from "../core/Banner";

const TITLE =
  "Echo is a place where people help each other by sharing skills and talents.";

const ActivityView = () => {
  const [state, setState] = useState({
    filter: "",
  });

  const { loading, error, data, refetch, fetchMore } = useQuery(ACTIONS_QUERY, {
    variables: {
      filter: state.filter,
      first: DefaultPaginationLimit,
      after: "",
    },
  });

  const {
    loading: loadingCommunities,
    error: errorCommunities,
    data: dataCommunities,
  } = useQuery(COMMUNITIES_QUERY);

  useEffect(() => {
    refetch({ filter: state.filter });
  }, [state.filter]); // eslint-disable-line

  const updateFilter = (filter: string) => {
    setState({
      ...state,
      filter: filter,
    });
  };

  if (loading || loadingCommunities) return null;
  if (error || errorCommunities) return null;

  const nodes = data.actions.edges.map((edge: ActionTypeEdge) => edge.node);
  const pageInfo = data.actions.pageInfo;

  const options = dataCommunities.communities.map((e: CommunityType) => [
    e.name,
    e.slug,
  ]);

  return (
    <React.Fragment>
      <DesktopHeader />
      <div className="tray three-column">
        <div className="col-left desktop">
          <div className="widgets">
            <Search
              filterMode={FilterMode.Filter}
              filterOptions={options}
              filterPlaceholder="Everyone"
              filterToggle={true}
              onChangeFilter={updateFilter}
              filter={state.filter}
            />
            <Greeting />
          </div>
        </div>
        <div className="col-center">
          <Navigation />
          <Header title={TITLE} />
          <Search
            filterMode={FilterMode.Filter}
            filterOptions={options}
            filterPlaceholder="Everyone"
            filterToggle={true}
            onChangeFilter={updateFilter}
            filter={state.filter}
          />
          <Banner />
          <div className="actions cntrlse">
            <div className="controls"></div>
            <ActionList
              actions={nodes}
              more={pageInfo.hasNextPage}
              onLoadMore={() => {
                if (pageInfo.hasNextPage) {
                  fetchMore({
                    variables: {
                      after: pageInfo.endCursor,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="col-right desktop">
          <div className="widgets">
            <CreateOffer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActivityView;
