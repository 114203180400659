const DEFAULT_HOST = window.location.host;
// const DEFAULT_HOST = "echo.civic.co";

export const DEFAULT_PATH = `//${DEFAULT_HOST}/graphql/`;

export const USER_KEY = "currentUser";

export const TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";

export const DefaultPaginationLimit = 15;

export enum Action {
  New,
  Edit,
  View,
}

export enum Step {
  Start,
  Category,
  Preview,
}

export enum Category {
  HelpAndAdvice = 1,
  Business = 2,
  DigitalAndCreative = 3,
  LearningWellbeingAndSkillshare = 4,
  Space = 5,
}

export const ROUTES = {
  ACTIVITY: "/activity",
  LOGIN: "/login",
  PASSWORD_RESET: "/password-reset",
  ACTIVATE: "/activate",
  MESSAGES: "/messages",
  OFFER: "/offer",
  PROFILE: "/profile",
  PEOPLE: "/people",
  SIGNUP: "/signup",
};

export enum FilterMode {
  Basic = "basic",
  Filter = "filter",
  Full = "full",
}

export enum ActivityType {
  JOINED = "joined",
  CREATED = "created",
}

export const availabilityOptions = ["Online", "In person"];

export const timeOptions = [
  { label: "1 hour", value: 1 },
  { label: "2 hours", value: 2 },
  { label: "3 hours", value: 3 },
  { label: "4 hours", value: 4 },
  { label: "5 hours", value: 5 },
];

export enum GQLAuthErrors {
  UNAUTHENTICATED = "Unauthenticated.",
  INVALID_TOKEN = "Invalid token.",
  EXPIRED_TOKEN = "Expired token.",
  NO_SUFFICIENT_PERMISSIONS = "Permissions found could not satisfy the required permissions.",
  NOT_VERIFIED = "Please verify your account.",
  MISSING_TOKEN = "No JWT found",
}
