import React from "react";
import { Link } from "react-router-dom";

// Assets
import { ReactComponent as LogoRGBSymbolSVG } from "../../assets/svg/brand/logo-rgb-symbol.svg";
import { ReactComponent as ChevronLeftSVG } from "../../assets/svg/icons/navigation/chevron_left_24px.svg";

type FormHeaderProps = {
  onBackLinkClick?: () => void;
};

const FormHeader = ({ onBackLinkClick }: FormHeaderProps) => (
  <div className="form-header">
    {onBackLinkClick ? (
      <a href="#" onClick={() => onBackLinkClick()}>
        <ChevronLeftSVG className="back-button" />
      </a>
    ) : (
      <Link to="./" className="reload-button">
        <ChevronLeftSVG className="back-button" />
      </Link>
    )}
    <LogoRGBSymbolSVG className="logo" />
  </div>
);

export default FormHeader;
