import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client/react";

import { CommunityType, UserType, UserTypeEdge } from "../../types";

import "./UserSelector.sass";

import CancelButton from "../core/CancelButton";
import ActionButton from "../core/ActionButton";
import Search from "../core/Search";

import { DefaultPaginationLimit, FilterMode, ROUTES } from "../../constants";
import { COMMUNITIES_QUERY } from "../accounts/queries";
import { USER_SEARCH_QUERY } from "../people/queries";
import { setError } from "../core/utils";
import { CREATE_DIALOGUE_MUTATION, DIALOGUES_QUERY } from "./queries";

const SEARCH_COPY = "Search for anyone in Echo";

type HeaderProps = {
  title: string;
  onNext?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Header = ({ title, onNext }: HeaderProps) => {
  return (
    <div className="header">
      <CancelButton pathname={ROUTES.MESSAGES} />
      <div className="title">{title}</div>
      {onNext && <ActionButton title={"Next"} onClick={onNext} />}
    </div>
  );
};

type UserItemProps = {
  user: UserType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const UserItem = ({ user, onClick }: UserItemProps) => {
  return (
    <div className={"user-item"}>
      <button onClick={onClick}>
        <div className="image">
          {user.profile?.picture && (
            <img
              src={user.profile?.picture}
              alt={user.profile?.preferredName}
            />
          )}
        </div>
        <div className="details">
          <div className="name">{user.profile?.preferredName}</div>
          <div className="title">{user.profile?.bio}</div>
        </div>
      </button>
    </div>
  );
};

type UserListProps = {
  title: string;
  users?: UserType[];
};

const UserList = ({ title, users = [] }: UserListProps) => {
  const navigate = useNavigate();

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`../${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  return (
    <div className="user-list">
      <div className="title">{title}</div>
      <div className="list">
        {users.map((user, index) => {
          return (
            <UserItem
              user={user}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                createDialogue({
                  variables: { userId: user.id },
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const UserSelector = () => {
  const [state, setState] = useState({
    query: "",
    filter: "",
    nodes: [],
    pageInfo: "",
    totalCount: 0,
    options: [],
  });

  const [executeSearch, { error, data }] = useLazyQuery(USER_SEARCH_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.userSearch) {
      setState({
        ...state,
        nodes:
          data.userSearch.edges.map((edge: UserTypeEdge) => edge.node) || [],
        pageInfo: data.userSearch.pageInfo,
        totalCount: data.userSearch.totalCount,
      });
    }
  }, [data]); // eslint-disable-line

  const { error: errorCommunities, data: dataCommunities } =
    useQuery(COMMUNITIES_QUERY);

  const runQuery = () => {
    console.info("running search");
    executeSearch({
      variables: {
        query: state.query,
        filter: state.filter,
        first: DefaultPaginationLimit,
        after: "",
      },
    });
  };

  // Initial set up.
  useEffect(() => {
    runQuery();
  }, []); // eslint-disable-line

  useEffect(() => {
    runQuery();
  }, [state.query]); // eslint-disable-line

  useEffect(() => {
    runQuery();
  }, [state.filter]); // eslint-disable-line

  useEffect(() => {
    if (dataCommunities && dataCommunities.communities) {
      setState({
        ...state,
        options: dataCommunities.communities.map((e: CommunityType) => [
          e.name,
          e.slug,
        ]),
      });
    }
  }, [dataCommunities]); // eslint-disable-line

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  const updateFilter = (filter: string) => {
    setState({
      ...state,
      filter: filter,
    });
  };

  // NOTE: Using guards for error states
  if (error || errorCommunities) return null;

  return (
    <div className="user-selector contained">
      <Header title={"Connect"} />
      <Search
        filterMode={FilterMode.Full}
        filterOptions={state.options}
        filterPlaceholder="Everyone"
        placeholder={SEARCH_COPY}
        onChangeQuery={updateQuery}
        onChangeFilter={updateFilter}
        query={state.query}
        filter={state.filter}
      />
      {state.nodes.length > 0 ? (
        <UserList title={"People"} users={state.nodes} />
      ) : (
        ""
      )}
    </div>
  );
};

export default UserSelector;
