import React, { ReactChildren, ReactChild } from "react";

import "./Modal.sass";

type ModalProps = {
  children: ReactChild | ReactChildren;
  className?: string;
  show: boolean;
  closeModal?: () => void;
};

export const Modal = ({
  children,
  className = "",
  show,
  closeModal,
}: ModalProps) => (
  <div
    className={`modal ${className}`}
    style={{ display: show ? "block" : "none" }}
  >
    <div className="overlay" onClick={closeModal} />
    <div className="modalContent">
      {/*<span className="close" onClick={closeModal}>
        &times;
      </span>*/}
      {children}
    </div>
  </div>
);
