import React, { useState } from "react";

import "./Toast.sass";

type ToastProps = {
  message: string;
  className?: string;
  show: boolean;
  closeLabel?: string;
};

export const Toast = ({
  message,
  className,
  show,
  closeLabel = "×",
}: ToastProps) => {
  const [state, setState] = useState({
    show: show,
  });
  return (
    <div
      className={`toast ${className}`}
      style={{ display: state.show ? "block" : "none" }}
    >
      <div className="toastWrap">
        <div className="toastContent">
          <span className="message">{message}</span>
          <span className="close" onClick={(e) => setState({ show: false })}>
            {closeLabel}
          </span>
        </div>
      </div>
    </div>
  );
};
