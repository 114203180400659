export const setError = (error: string) => {
  console.info(error);
};

export const isEmailAddress = (email: string) => {
  const r = new RegExp(String.raw`^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$`);
  return r.test(email);
};

const LOCALE = "en-GB";

const timePast = (previous: string) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;

  const current = new Date();

  const elapsed = current.getTime() - new Date(previous).getTime();

  // const options = { weekday: "long" };

  if (elapsed < msPerDay) {
    return formatTimeSimple(previous);
  } else if (elapsed < msPerWeek) {
    return new Intl.DateTimeFormat(LOCALE, { weekday: "long" }).format(
      new Date(previous)
    );
  } else {
    return new Date(previous).toLocaleDateString(LOCALE);
  }
};

export const formatTime = (timestamp: string | undefined) => {
  // console.info(timestamp);
  return timestamp ? timePast(timestamp) : "";
};

export const formatTimeSimple = (timestamp: string | undefined) => {
  if (timestamp) {
    const time = new Date(timestamp);
    return `${time.toLocaleTimeString(LOCALE, {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }
  return "";
};

export const getCookie = (name: string) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};
