import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

// Components
import { TextSignUp } from "./core";

// Configuration
import { ROUTES } from "../../constants";

// Queries
import { VERIFY_ACCOUNT_MUTATION } from "./queries";

// Assets
import { ErrorType } from "../../types";
import { Toast } from "../core/Toast";

const VerifyAccount = () => {
  const [formState, setFormState] = useState({
    errors: [] as ErrorType[],
  });

  const [verifyAccount, resultVerifyAccount] = useMutation(
    VERIFY_ACCOUNT_MUTATION
  );

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      verifyAccount({
        variables: {
          token: token,
        },
      });
    }
  }, [token, verifyAccount]);

  useEffect(() => {
    if (resultVerifyAccount.data) {
      const success = resultVerifyAccount.data.verifyAccount.success;
      if (success) {
        navigate(ROUTES.ACTIVITY);
      } else {
        setFormState({
          ...formState,
          errors: [
            {
              type: "",
              message:
                "There was an issue verifying your account. Please check and try again.",
            },
          ],
        });
      }
    }
  }, [resultVerifyAccount.data, formState, navigate]);

  return (
    <div className="auth contained">
      <div className="activate-mode">
        <div className="verify-account">
          {formState.errors.length !== 0 && (
            <React.Fragment>
              <div className="error">{formState.errors[0].message}</div>
              <Toast
                show={true}
                className="error"
                message="Something wasn’t right"
              />
            </React.Fragment>
          )}
        </div>
        <TextSignUp />
      </div>
    </div>
  );
};

export default VerifyAccount;
