import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { SkillType } from "../../types";
import { availabilityOptions, Category } from "../../constants";

import { ME_QUERY } from "../accounts/queries";

import {
  OFFER_QUERY,
  SKILLS_QUERY,
  UPDATE_OFFER_MUTATION,
  UPDATE_OFFER_PICTURE_MUTATION,
  OFFER_DRAFT_QUERY,
} from "./queries";

import ActionButton from "../core/ActionButton";

import { ReactComponent as AddSVG } from "../../assets/svg/icons/button/add_24px.svg";
import { ReactComponent as PencilSVG } from "../../assets/svg/icons/pencil.svg";

// Components
import CancelLink from "../core/CancelLink";
import Selector from "../core/Selector";
import BasicEditor from "../core/BasicEditor";

enum Step {
  Start,
  Category,
  Preview,
}

const OfferEdit = () => {
  const { offerId } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(OFFER_QUERY, {
    variables: { id: offerId },
    skip: offerId ? false : true,
  });

  const { data: dataOfferDraft } = useQuery(OFFER_DRAFT_QUERY, {
    variables: {},
    skip: offerId ? true : false,
  });

  const {
    loading: loadingUser,
    error: errorUser,
    data: dataMe,
  } = useQuery(ME_QUERY);

  const {
    loading: loadingSkills,
    error: errorSkills,
    data: dataSkills,
  } = useQuery(SKILLS_QUERY);

  const offer = data?.offer || dataOfferDraft?.offerDraft;
  const user = dataMe?.me;

  const [formState, setFormState] = useState({
    step: Step.Start,
    id: offer?.id,
    title: offer?.title || "",
    description: offer?.description || "",
    picture: offer?.picture || "",
    availability: offer?.availability || [],
    skills:
      offer?.skills?.map((v: { title: string }) => v.title) || ([] as string[]),
    price: offer?.price || 1,
    saving: false,
  });

  const [descriptionState, setDescriptionState] = useState(
    offer?.description || ""
  );

  useEffect(() => {
    if (descriptionState) {
      setFormState({
        ...formState,
        description: descriptionState,
      });
    }
  }, [descriptionState, formState]);

  useEffect(() => {
    if (dataOfferDraft) {
      setFormState({
        ...formState,
        ...dataOfferDraft?.offerDraft,
      });
    }
  }, [dataOfferDraft, formState]);

  const baseVariables = {
    id: formState.id,
    title: formState.title,
    description: formState.description,
    availability: formState.availability,
    skills: formState.skills,
    price: formState.price,
  };

  const [updateOffer, result] = useMutation(UPDATE_OFFER_MUTATION, {
    variables: offer ? { ...baseVariables, id: offer?.id } : baseVariables,
  });

  const [updateOfferPicture, resultPicture] = useMutation(
    UPDATE_OFFER_PICTURE_MUTATION
  );

  const imgRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (result.data) {
      setFormState({ ...formState, saving: false });
      navigate(`/profile/${user.id}/${result.data.updateOffer.offer.id}`, {
        state: { prevPath: `/profile/${user.id}` },
      });
    }
  }, [result.data, formState, navigate, user.id]);

  useEffect(() => {
    if (resultPicture.data) {
      const id = resultPicture.data.updateOfferPicture.offer.id;
      const picture = resultPicture.data.updateOfferPicture.offer.picture;
      setFormState({
        ...formState,
        id: id,
        picture: picture,
      });
    }
  }, [resultPicture.data, formState]);

  if (loadingUser || errorUser) return null;

  if (loadingSkills || errorSkills) return null;

  const validateStart = () => {
    if (
      formState.title !== "" &&
      formState.description !== "" &&
      formState.availability.length > 0
    ) {
      return true;
    }
    return false;
  };

  const validateCategory = () => {
    if (formState.skills.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="edit cntrlse">
      {formState.step === Step.Start && (
        <div className={validateStart() ? "step-0 valid" : "step-0"}>
          <div id="edit-heading">
            <div className="left">
              <CancelLink pathname={offerId ? "../" : ""} />
            </div>
            <div className="right">
              <ActionButton
                type="btn-next"
                title="Next"
                onClick={(e) => {
                  e.preventDefault();
                  if (validateStart()) {
                    setFormState({
                      ...formState,
                      step: Step.Category,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="fields-heading">
            <h3>{offerId ? "Edit your offer" : "Write an offer"}</h3>
          </div>
          <div className="fields">
            <div className="field">
              <label htmlFor="title">
                <h6>What can you offer?</h6>
              </label>
              <input
                type="text"
                name="title"
                value={formState.title}
                placeholder="Write a short, clear title"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="description">
                <h6>Write a short description</h6>
              </label>
              <BasicEditor
                initialValue={descriptionState}
                onEditorChange={(content: string) => {
                  setDescriptionState(content);
                }}
              />
            </div>
            <div className="field image">
              <div className="simple-image-input">
                <input
                  type="file"
                  name="uploadPicture"
                  accept="image/*"
                  ref={imgRef}
                  onChange={({ target: { validity, files } }) => {
                    if (validity.valid && files?.length) {
                      updateOfferPicture({
                        variables: { id: formState.id, file: files[0] },
                      });
                    }
                  }}
                />
                <label
                  htmlFor="uploadPicture"
                  onClick={() => imgRef.current?.click()}
                >
                  <AddSVG />
                  <h6>{formState.picture ? "Change image" : "Add an image"}</h6>
                  <small>
                    Please keep images (JPG/PNG) less than 5 MB in size
                  </small>
                </label>
                {formState.picture.length !== 0 && (
                  <img
                    src={formState.picture}
                    onClick={() => imgRef.current?.click()}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="field">
              <label htmlFor="availability">
                <h6>This is available</h6>
              </label>
              <Selector
                options={availabilityOptions}
                selected={formState.availability}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    availability: v,
                  });
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="price">
                <h6>Estimate how long this will take in hours</h6>
              </label>
              <input
                type="range"
                id="price"
                name="price"
                min=".5"
                max="5"
                value={formState.price}
                step="0.5"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    price: parseFloat(e.target.value).toFixed(1) || 1.0,
                  })
                }
              />
              <label htmlFor="price">
                {formState.price} Hour{formState.price !== 1 ? "s" : ""}
              </label>
            </div>
          </div>
        </div>
      )}
      {formState.step === Step.Category && (
        <div className="step-1">
          <div id="edit-heading">
            <div className="left">
              <CancelLink pathname={document.referrer} />
            </div>
            <div className="right">
              <ActionButton
                type="btn-previous"
                title="Previous"
                onClick={(e) => {
                  e.preventDefault();
                  setFormState({
                    ...formState,
                    step: Step.Start,
                  });
                }}
              />
              <ActionButton
                type="btn-preview"
                title="Show me a preview"
                onClick={(e) => {
                  e.preventDefault();
                  if (validateCategory()) {
                    setFormState({
                      ...formState,
                      step: Step.Preview,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="fields-heading">
            <h3>Choose a category</h3>
          </div>
          <div className="fields">
            <div className="field">
              <label htmlFor="skills">
                <h6>Help and advice</h6>
              </label>
              <Selector
                options={dataSkills.skills
                  .filter(
                    (s: SkillType) => s.category === Category.HelpAndAdvice
                  )
                  .map((s: SkillType) => s.title)}
                selected={formState.skills}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    skills: v,
                  });
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="skills">
                <h6>Business</h6>
              </label>
              <Selector
                options={dataSkills.skills
                  .filter((s: SkillType) => s.category === Category.Business)
                  .map((s: SkillType) => s.title)}
                selected={formState.skills}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    skills: v,
                  });
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="skills">
                <h6>Digital and creative</h6>
              </label>
              <Selector
                options={dataSkills.skills
                  .filter(
                    (s: SkillType) => s.category === Category.DigitalAndCreative
                  )
                  .map((s: SkillType) => s.title)}
                selected={formState.skills}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    skills: v,
                  });
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="skills">
                <h6>Learning, wellbeing and skillshare</h6>
              </label>
              <Selector
                options={dataSkills.skills
                  .filter(
                    (s: SkillType) =>
                      s.category === Category.LearningWellbeingAndSkillshare
                  )
                  .map((s: SkillType) => s.title)}
                selected={formState.skills}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    skills: v,
                  });
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="skills">
                <h6>Space</h6>
              </label>
              <Selector
                options={dataSkills.skills
                  .filter((s: SkillType) => s.category === Category.Space)
                  .map((s: SkillType) => s.title)}
                selected={formState.skills}
                onSelect={(v: string[]) => {
                  setFormState({
                    ...formState,
                    skills: v,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
      {formState.step === Step.Preview && (
        <div className="step-2">
          <div id="edit-heading">
            <div className="left">
              <CancelLink pathname={document.referrer} />
            </div>
            <div className="right">
              <ActionButton
                type="btn-post"
                title={formState.saving ? "Saving" : "Post it"}
                onClick={(e) => {
                  e.preventDefault();
                  // Only allow one operation at a time
                  if (formState.saving !== true) {
                    setFormState({ ...formState, saving: true });
                    updateOffer();
                  }
                }}
              />
            </div>
          </div>
          <div className="fields-heading">
            <h3>All okay?</h3>
            <div className="preview">
              <p>
                This offer will be available to everyone in the Echo network
                provided you have enough hours left to give this month
              </p>
              <div className="offer">
                <div className="offer-title f-b">{formState.title}</div>
                <div className="offer-picture">
                  {formState.picture.length !== 0 && (
                    <img
                      src={formState.picture}
                      onClick={() => imgRef.current?.click()}
                      alt=""
                    />
                  )}
                </div>
                <div
                  className="offer-short"
                  dangerouslySetInnerHTML={{ __html: formState.description }}
                ></div>
              </div>
              <div className="edit-link">
                <a
                  className="edit"
                  onClick={() => {
                    setFormState({
                      ...formState,
                      step: Step.Start,
                    });
                  }}
                >
                  <PencilSVG />
                  Edit
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="step-tracker">
        <div className="step-wrap">
          <div
            className={
              "step" + (formState.step === Step.Start ? " active" : "")
            }
          ></div>
          <div
            className={
              "step" + (formState.step === Step.Category ? " active" : "")
            }
          ></div>
          <div
            className={
              "step" + (formState.step === Step.Preview ? " active" : "")
            }
          ></div>
        </div>
      </div>
    </div>
  );
};

export default OfferEdit;
