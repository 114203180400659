import React from "react";

type AuthButtonProps = {
  className?: string;
  title: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const AuthButton = ({ className, title, onClick }: AuthButtonProps) => (
  <div className={`auth-button ${className} f-b`}>
    <a onClick={onClick}>
      {title}
    </a>
  </div>
);

export default AuthButton;