import React from "react";
import { useParams } from "react-router-dom";

import DesktopHeader from "../core/DesktopHeader";

import Conversation from "./Conversation";
import Messages from "./Messages";

const Messaging = () => {
  const { id } = useParams();
  const conversation = id ? <Conversation id={id} /> : null;
  return (
    <React.Fragment>
      <DesktopHeader />
      <div className="tray two-column">
        <div className="col-left">
          {id && (
            <>
              <div className="messages-wrap desktop">
                <Messages />
              </div>
              <div className="mobile-only">{conversation}</div>
            </>
          )}
          {!id && (
            <div className="messages-wrap">
              <Messages />
            </div>
          )}
        </div>
        <div className="col-right desktop">{conversation}</div>
      </div>
    </React.Fragment>
  );
};

export default Messaging;
