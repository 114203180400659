import React, { useState } from "react";

import { ReactComponent as SearchSVG } from "../../assets/svg/icons/search.svg";
import { ReactComponent as OptionsSVG } from "../../assets/svg/icons/options.svg";

import "./Search.sass";

import { FilterMode } from "../../constants";

type SearchProps = {
  placeholder?: string;
  className?: string;
  filterMode?: string;
  filterPlaceholder?: string;
  filterToggle?: boolean;
  filterOptions?: string[][];
  query?: string;
  filter?: string;
  onChangeQuery?: (query: string) => void;
  onChangeFilter?: (filter: string) => void;
};

const Search = ({
  placeholder = "",
  className = "",
  filterMode = FilterMode.Basic,
  filterPlaceholder = "All",
  filterToggle = false,
  filterOptions = [],
  query = "",
  filter = "",
  onChangeQuery,
  onChangeFilter,
}: SearchProps) => {
  const [state, updateState] = useState({
    filterToggle: filterToggle,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeQuery && onChangeQuery(event.target.value);
  };

  return (
    <div className={`search-component ${filterMode}`}>
      <div
        className={`search-widget${
          filterMode === FilterMode.Filter ? " hidden" : ""
        }`}
      >
        <div className={`search ${className}`}>
          <div className="search-wrapper">
            <SearchSVG />
            <input
              className="search-input"
              type="text"
              placeholder={placeholder}
              value={query}
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className={`filter-toggle${state.filterToggle ? " active" : ""}`}>
          <button
            className="toggle"
            onClick={(e) =>
              updateState({
                ...state,
                filterToggle: !state.filterToggle,
              })
            }
          >
            <OptionsSVG />
          </button>
        </div>
      </div>
      <div className={`filter-widget${state.filterToggle ? "" : " hidden"}`}>
        <div className="entries">
          {[[filterPlaceholder, ""], ...filterOptions].map((entry, index) => (
            <span
              className={`entry${entry[1] === filter ? " active" : ""}`}
              key={index}
              onClick={(e) => onChangeFilter && onChangeFilter(entry[1])}
            >
              {entry[0]}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
