import React from "react";

import { UserType } from "../../types";
import ProfileItem from "./ProfileItem";

import "./ProfileList.sass";

type ProfileListProps = {
  users?: UserType[];
  totalCount: number;
  more: boolean;
  onLoadMore: any;
};

const PROFILES_TITLE = "Echo members who can help: ";

const ProfileList = ({
  users,
  totalCount,
  more,
  onLoadMore,
}: ProfileListProps) => {
  if (!users?.length) return null;

  return (
    <div className="profile-list">
      <div className="profiles">
        <div className="title">
          {PROFILES_TITLE} {totalCount}
        </div>
        <div className="items">
          {users.map((user: UserType, index: string | number | undefined) => (
            <ProfileItem user={user} key={index} />
          ))}
          {more && (
            <button className="btn action" onClick={onLoadMore}>
              Load more
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileList;
