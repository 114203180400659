import { gql } from "@apollo/client";

export const CREATE_MESSAGE_MUTATION = gql`
  mutation SendMessageMutation($text: String!, $dialogueId: String!) {
    createMessage(text: $text, dialogueId: $dialogueId) {
      status
      graphQLErrors
      message {
        id
        text
        author {
          id
          profile {
            id
            preferredName
            picture
          }
        }
        own
        timestamp
        dialogue {
          id
        }
      }
      notifyUserIds
    }
  }
`;

export const SEND_ECHOS_MUTATION = gql`
  mutation SendEchos(
    $dialogueId: String!
    $time: Int!
    $offerId: String
    $post: String
  ) {
    sendEchos(
      dialogueId: $dialogueId
      time: $time
      offerId: $offerId
      post: $post
    ) {
      status
      graphQLErrors
      message {
        id
        text
        author {
          id
          profile {
            id
            preferredName
            picture
          }
        }
        own
        dialogue {
          id
        }
        trade {
          id
          supplier {
            id
          }
          recipient {
            id
          }
          units
        }
      }
      notifyUserIds
    }
  }
`;

export const CREATE_DIALOGUE_MUTATION = gql`
  mutation CreateDialogueMutation($userId: String!) {
    createDialogue(userId: $userId) {
      status
      graphQLErrors
      dialogue {
        id
        users {
          id
        }
      }
      notifyUserIds
    }
  }
`;

const MESSAGE_FIELDS = gql`
  fragment MessageFields on MessageType {
    id
    text
    author {
      id
      profile {
        id
        preferredName
        picture
      }
    }
    own
    trade {
      id
      supplier {
        id
        profile {
          id
          preferredName
        }
      }
      recipient {
        id
        profile {
          id
          preferredName
        }
      }
      units
    }
    timestamp
  }
`;

export const DIALOGUES_QUERY = gql`
  ${MESSAGE_FIELDS}
  query Dialogues {
    dialogues {
      id
      creator {
        id
      }
      users {
        id
      }
      meta
      formattedTitle
      defaultPicture
      messages {
        ...MessageFields
      }
      lastMessage
      timestamp
    }
  }
`;

export const DIALOGUES_SEARCH_QUERY = gql`
  query DialoguesSearch($query: String!) {
    dialoguesSearch(query: $query) {
      id
      creator {
        id
      }
      users {
        id
      }
      meta
      formattedTitle
      defaultPicture
      lastMessage
    }
  }
`;

export const DIALOGUE_QUERY = gql`
  ${MESSAGE_FIELDS}
  query Dialogue($id: String!) {
    dialogue(id: $id) {
      id
      creator {
        id
      }
      users {
        id
        profile {
          id
          preferredName
          picture
          offers {
            id
            title
          }
        }
      }
      meta
      formattedTitle
      lastMessage
      messages {
        ...MessageFields
      }
    }
  }
`;

export const USERS_CONNECTIONS_QUERY = gql`
  query UsersConnections {
    usersConnections {
      id
      profile {
        id
        preferredName
        bio
        picture
      }
    }
  }
`;

export const USERS_NETWORK_QUERY = gql`
  query UsersNetwork {
    usersNetwork {
      id
      profile {
        id
        preferredName
        bio
        picture
      }
    }
  }
`;
