import React from "react";

import { formatTime } from "../core/utils";

import { MessageType } from "../../types";

import "./Message.sass";

type MessageProps = {
  message: MessageType;
};

export const messageClass = (message: MessageType) =>
  message.own ? "message own" : "message";

export const Message = ({ message }: MessageProps) => (
  <div className={messageClass(message)}>
    <div className="heading"></div>
    <div className="image">
      {message.author?.profile?.picture && (
        <img
          src={message.author?.profile?.picture}
          alt={message.author?.profile?.preferredName}
        />
      )}
    </div>
    <div className="content">{message.text}</div>
    <div className="status">
      <div className="timestamp" data-raw={message.timestamp}>
        {formatTime(message.timestamp)}
      </div>
    </div>
  </div>
);
