import React from "react";

// Assets
import { ReactComponent as LogoSVG } from "../../assets/svg/brand/logo-with-tag.svg";

export const LogoHeader = () => (
  <div className="logo-header">
    <LogoSVG className="logo" />
  </div>
);
