import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

// Types
import { SkillType } from "../../types";
import { Category, ROUTES, timeOptions } from "../../constants";

// Queries
import {
  SKILLS_QUERY,
  UPDATE_USER_PROFILE_MUTATION,
  UPDATE_USER_PROFILE_PICTURE_MUTATION,
  UPDATE_USER_PROFILE_SKILLS_MUTATION,
  USER_PROFILE_QUERY,
} from "./queries";

// Components
import Selector from "../core/Selector";
import ActionButton from "../core/ActionButton";
import { FormTitle } from "../core/Forms";
import SingleSelector from "../core/SingleSelector";
import ImageInput from "../core/ImageInput";

// Assets
import chevronLeftSVGURL from "../../assets/svg/icons/navigation/chevron_left_24px.svg";
import starSVGURL from "../../assets/svg/icons/star.svg";
import starNoSVGURL from "../../assets/svg/icons/star-no.svg";
import profileImageSVGURL from "../../assets/svg/icons/profile-image.svg";

enum Step {
  Skills,
  SortSkills,
}

enum Mode {
  TIME = "time",
  SKILLS = "skills",
}

const ProfileEdit = () => {
  const { userId, mode } = useParams();

  const { loading, data } = useQuery(USER_PROFILE_QUERY, {
    variables: { id: userId },
  });

  const { data: dataSkills } = useQuery(SKILLS_QUERY);

  const [coreState, setCoreState] = useState({
    skills: [],
    // communities: [],
  });

  useEffect(() => {
    if (dataSkills) {
      setCoreState({
        ...coreState,
        skills: dataSkills.skills,
      });
    }
  }, [dataSkills, coreState]);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    project: "",
    picture: "",
    skills: [] as string[],
    starSkills: [] as string[],
    time: 0,
    step: Step.Skills,
    savingImage: false,
    editingImage: false,
  });

  useEffect(() => {
    if (data) {
      setFormState({
        ...formState,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        bio: data.user.profile?.bio,
        project: data.user.profile?.project,
        picture: data.user.profile?.picture,
        skills: data.user.profile?.skills?.map(
          (v: { skill: { title: any } }) => v.skill.title
        ),
        starSkills: data.user.profile?.skills
          ?.filter((v: { offering: boolean }) => v.offering === true)
          .map((v: { skill: { title: any } }) => v.skill.title),
        time: data.user.profile?.time,
      });
    }
  }, [loading, data, formState]);

  const [updateUserProfile, result] = useMutation(
    UPDATE_USER_PROFILE_MUTATION,
    {
      variables: {
        firstName: formState.firstName,
        lastName: formState.lastName,
        bio: formState.bio,
        project: formState.project,
        time: formState.time,
      },
    }
  );

  const [updateUserProfileSkills, resultSkills] = useMutation(
    UPDATE_USER_PROFILE_SKILLS_MUTATION,
    {
      variables: {
        skills: formState.skills,
        starSkills: formState.starSkills,
      },
    }
  );

  const [updateUserProfilePicture, resultPicture] = useMutation(
    UPDATE_USER_PROFILE_PICTURE_MUTATION
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (result.data) {
      navigate(`${ROUTES.PROFILE}/${data.user.id}`);
    }
  }, [result.data, data.user.id, navigate]);

  useEffect(() => {
    if (resultSkills.data) {
      navigate(`${ROUTES.PROFILE}/${data.user.id}`);
    }
  }, [resultSkills.data, data.user.id, navigate]);

  useEffect(() => {
    if (resultPicture.data) {
      const picture =
        resultPicture.data.updateUserProfilePicture.user.profile?.picture;
      setFormState({
        ...formState,
        picture: picture,
        savingImage: false,
        editingImage: false,
      });
    }
  }, [resultPicture.data, formState]);

  const validateSkills = () => {
    if (formState.skills.length > 0) {
      return true;
    }
    return false;
  };

  const coreFields = (
    <div className="edit cntrlse">
      <div id="edit-heading">
        <div className="left">
          <span className="back">
            <Link to={`/profile/${userId}`}>
              <img src={chevronLeftSVGURL} alt="Back" />
            </Link>
          </span>
        </div>
        <div className="right">
          <ActionButton
            type="btn-follow"
            title="Update"
            onClick={() => {
              // e.preventDefault();
              updateUserProfile();
            }}
          />
        </div>
      </div>
      <div className="fields-heading">
        <h3>Let’s get to know you</h3>
      </div>
      <div className="fields">
        <div className="field">
          <label htmlFor="firstname">
            <h6>First name</h6>
          </label>
          <input
            type="text"
            name="firstname"
            value={formState.firstName}
            placeholder="Jane"
            onChange={(e) =>
              setFormState({
                ...formState,
                firstName: e.target.value,
              })
            }
          />
        </div>
        <div className="field">
          <label htmlFor="lastname">
            <h6>Last name</h6>
          </label>
          <input
            type="text"
            name="lastname"
            value={formState.lastName}
            placeholder="Smith"
            onChange={(e) =>
              setFormState({
                ...formState,
                lastName: e.target.value,
              })
            }
          />
        </div>
        <div className="field">
          <label htmlFor="bio">
            <h6>Write a personal and professional tag line</h6>
          </label>
          <input
            type="text"
            name="bio"
            value={formState.bio}
            placeholder="Marketer, Mom and Yogi"
            onChange={(e) =>
              setFormState({
                ...formState,
                bio: e.target.value,
              })
            }
          />
        </div>
        <div className="field image">
          <label htmlFor="image">
            <h6>Choose a profile picture</h6>
          </label>
          <small>
            This helps people get to know you, and recognise you if you meet up
          </small>
          <ImageInput
            imgURL={formState.picture}
            defaultImage={profileImageSVGURL}
            saving={formState.savingImage}
            editing={formState.editingImage}
            uploadImage={(params) => {
              setFormState({ ...formState, savingImage: true });
              updateUserProfilePicture(params);
            }}
            startEditing={() => {
              setFormState({ ...formState, editingImage: true });
            }}
            stopEditing={() => {
              setFormState({ ...formState, editingImage: false });
            }}
          />
        </div>
        <div className="field">
          <label htmlFor="project">
            <h6>Business or project</h6>
          </label>
          <input
            type="text"
            name="project"
            value={formState.project}
            placeholder="XYZ Club"
            onChange={(e) =>
              setFormState({
                ...formState,
                project: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );

  const advanceSkillsEntry = () => {
    if (validateSkills()) {
      if (formState.step === Step.SortSkills) {
        updateUserProfileSkills();
        setFormState({
          ...formState,
          step: Step.Skills,
        });
      }
      setFormState({
        ...formState,
        step: Step.SortSkills,
      });
    }
  };

  const skillsFields = dataSkills ? (
    <div className="edit cntrlse skill-edit-form">
      <div id="edit-heading">
        <div className="left">
          <span className="back">
            <Link to={`/profile/${userId}`}>
              <img src={chevronLeftSVGURL} alt="Back" />
            </Link>
          </span>
        </div>
        <div className="right">
          <ActionButton
            type="btn-follow"
            title={formState.step === Step.Skills ? "Next" : "Update"}
            onClick={(e) => {
              e.preventDefault();
              advanceSkillsEntry();
            }}
          />
        </div>
      </div>
      {formState.step === Step.Skills && (
        <div className="skills fields">
          <FormTitle title={"How can you help?"} />

          <div className="field">
            <label htmlFor="skills">
              <h6>Help and advice</h6>
            </label>
            <Selector
              options={coreState.skills
                .filter((s: SkillType) => s.category === Category.HelpAndAdvice)
                .map((s: SkillType) => s.title)}
              selected={formState.skills}
              onSelect={(v: string[]) => {
                setFormState({
                  ...formState,
                  skills: v,
                });
              }}
            />
          </div>
          <div className="field">
            <label htmlFor="skills">
              <h6>Business</h6>
            </label>
            <Selector
              options={coreState.skills
                .filter((s: SkillType) => s.category === Category.Business)
                .map((s: SkillType) => s.title)}
              selected={formState.skills}
              onSelect={(v: string[]) => {
                setFormState({
                  ...formState,
                  skills: v,
                });
              }}
            />
          </div>
          <div className="field">
            <label htmlFor="skills">
              <h6>Digital and creative</h6>
            </label>
            <Selector
              options={coreState.skills
                .filter(
                  (s: SkillType) => s.category === Category.DigitalAndCreative
                )
                .map((s: SkillType) => s.title)}
              selected={formState.skills}
              onSelect={(v: string[]) => {
                setFormState({
                  ...formState,
                  skills: v,
                });
              }}
            />
          </div>
          <div className="field">
            <label htmlFor="skills">
              <h6>Learning, wellbeing and skillshare</h6>
            </label>
            <Selector
              options={coreState.skills
                .filter(
                  (s: SkillType) =>
                    s.category === Category.LearningWellbeingAndSkillshare
                )
                .map((s: SkillType) => s.title)}
              selected={formState.skills}
              onSelect={(v: string[]) => {
                setFormState({
                  ...formState,
                  skills: v,
                });
              }}
            />
          </div>
          <div className="field">
            <label htmlFor="skills">
              <h6>Space</h6>
            </label>
            <Selector
              options={coreState.skills
                .filter((s: SkillType) => s.category === Category.Space)
                .map((s: SkillType) => s.title)}
              selected={formState.skills}
              onSelect={(v: string[]) => {
                setFormState({
                  ...formState,
                  skills: v,
                });
              }}
            />
          </div>
        </div>
      )}
      {formState.step === Step.SortSkills && (
        <div className="sort-skills fields">
          <FormTitle title={"Star what you are most confident in"} />
          <div className="field">
            <label htmlFor="sort-skills">
              <h6>Select</h6>
            </label>
            <Selector
              className="sort-skills"
              selectedIcon={starSVGURL}
              deselectedIcon={starNoSVGURL}
              options={formState.skills}
              selected={formState.starSkills}
              onSelect={(v: string[]) => {
                setFormState({
                  ...formState,
                  starSkills: v,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  ) : null;

  const timeFields = (
    <div className="edit cntrlse skill-edit-form">
      <div id="edit-heading">
        <span className="back">
          <Link to={`/profile/${userId}`}>
            <img src={chevronLeftSVGURL} alt="Back" />
          </Link>
        </span>
        <ActionButton
          type="btn-follow"
          title={"Update"}
          onClick={(e) => {
            e.preventDefault();
            updateUserProfile();
          }}
        />
      </div>
      <div className="time fields">
        <FormTitle title={"How much time can you help each month?"} />
        <div className="field">
          <SingleSelector
            className="sort-skills"
            options={timeOptions}
            selected={`${formState.time}`}
            onSelect={(v: string | number) => {
              setFormState({
                ...formState,
                time: parseInt(`${v}`),
              });
            }}
          />
        </div>
      </div>
    </div>
  );

  switch (mode) {
    case Mode.TIME:
      return <div className="profile-edit-mode">{timeFields}</div>;
    case Mode.SKILLS:
      return <div className="profile-edit-mode">{skillsFields}</div>;
    default:
      return <div className="profile-edit-mode">{coreFields}</div>;
  }
};

export default ProfileEdit;
