import React from "react";

import { useQuery } from "@apollo/client";

import { ME_QUERY } from "../accounts/queries";

type GreetingProps = {
  message?: string;
};

const Greeting = ({
  message = "Let’s see what’s happening on Echo today.",
}: GreetingProps) => {
  const { loading, error, data } = useQuery(ME_QUERY);

  if (loading || error) return null;

  const user = data.me;

  return (
    <div className="greeting">
      <span className="name f-b">Hi {user.firstName} 👋</span>
      <br /> {message}
    </div>
  );
};

export default Greeting;
