import { gql } from "@apollo/client";

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfileBase(
    $firstName: String
    $lastName: String
    $bio: String
    $project: String
    $time: Int
  ) {
    updateUserProfileBase(
      firstName: $firstName
      lastName: $lastName
      bio: $bio
      project: $project
      time: $time
    ) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          project
          time
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_FULL_MUTATION = gql`
  mutation UpdateUserProfile(
    $firstName: String
    $lastName: String
    $bio: String
    $project: String
    $skills: [String]
    $starSkills: [String]
    $time: Int
  ) {
    updateUserProfile(
      firstName: $firstName
      lastName: $lastName
      bio: $bio
      project: $project
      skills: $skills
      starSkills: $starSkills
      time: $time
    ) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          project
          time
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_SKILLS_MUTATION = gql`
  mutation UpdateUserProfileSkills($skills: [String], $starSkills: [String]) {
    updateUserProfileSkills(skills: $skills, starSkills: $starSkills) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          picture
          project
          skills {
            skill {
              id
              title
            }
            offering
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_PICTURE_MUTATION = gql`
  mutation UpdateUserProfilePicture($file: Upload!) {
    updateUserProfilePicture(file: $file) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          preferredName
          bio
          picture
          project
          skills {
            skill {
              id
              title
            }
            offering
          }
        }
      }
    }
  }
`;

export const UPDATE_OFFER_PICTURE_MUTATION = gql`
  mutation UpdateOfferPicture($id: String, $file: Upload!) {
    updateOfferPicture(id: $id, file: $file) {
      status
      formErrors
      offer {
        id
        picture
      }
    }
  }
`;

export const USER_PROFILE_QUERY = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      isCurrentUser
      profile {
        id
        preferredName
        bio
        picture
        project
        time
        taken
        skills {
          skill {
            id
            title
          }
          offering
        }
        offers {
          id
          title
          short
        }
        notes {
          creator {
            id
            profile {
              id
              preferredName
            }
          }
          text
        }
      }
    }
  }
`;

export const USER_PROFILE_SHORT_QUERY = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      isCurrentUser
      profile {
        id
        preferredName
        picture
        project
        time
        taken
        skills {
          skill {
            id
            title
          }
          offering
        }
      }
    }
  }
`;

export const OFFER_QUERY = gql`
  query Offer($id: String!) {
    offer(id: $id) {
      id
      title
      short
      description
      picture
      price
      skills {
        id
        title
      }
      taken
    }
  }
`;

export const OFFER_DRAFT_QUERY = gql`
  query OfferDraft {
    offerDraft {
      id
      title
      short
      description
      picture
      price
      skills {
        id
        title
      }
      taken
    }
  }
`;

export const USER_SEARCH_QUERY = gql`
  query UserSearch(
    $query: String!
    $filter: String
    $first: Int
    $after: String
  ) {
    userSearch(
      query: $query
      communities: $filter
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          firstName
          lastName
          profile {
            id
            preferredName
            bio
            picture
            project
            time
            taken
            skills {
              skill {
                id
                title
              }
              offering
            }
            offers {
              id
              title
              short
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edgeCount
    }
  }
`;

export const UPDATE_OFFER_MUTATION = gql`
  mutation UpdateOffer(
    $id: String
    $title: String!
    $description: String!
    $availability: [String]
    $skills: [String]
    $price: Float
  ) {
    updateOffer(
      id: $id
      title: $title
      description: $description
      availability: $availability
      skills: $skills
      price: $price
    ) {
      status
      formErrors
      offer {
        id
        title
        description
        picture
        availability
        skills {
          id
          title
        }
        price
      }
    }
  }
`;

export const DELETE_OFFER_MUTATION = gql`
  mutation DeleteOffer($id: String!) {
    deleteOffer(id: $id) {
      status
      formErrors
    }
  }
`;

export const SKILLS_QUERY = gql`
  query Skills {
    skills {
      title
      category
    }
  }
`;
