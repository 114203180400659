import React from "react";

import Slider from "react-slick";

import { UserType, NoteType } from "../../types";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./CarouselList.sass";

type NoteItemProps = {
  note: NoteType;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const NoteItem = ({ note }: NoteItemProps) => (
  <div className="item">
    <div className="item-title f-b">{note.creator.profile?.preferredName}</div>
    <div className="item-short">{note.text}</div>
  </div>
);

type NoteListProps = {
  user: UserType;
};

const NoteList = ({ user }: NoteListProps) => (
  <div className="carousel-list">
    <Slider dots={true} arrows={false}>
      {user.profile?.notes?.map(
        (note: NoteType, index: string | number | undefined) => (
          <NoteItem note={note} key={index} onClick={() => null} />
        )
      )}
    </Slider>
  </div>
);

export default NoteList;
