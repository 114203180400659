import React, { useEffect, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";

// Components
import Navigation from "../core/Navigation";
import Header from "../core/Header";
import Search from "../core/Search";
import ProfileList from "./ProfileList";
import Greeting from "../widgets/Greeting";

// Types
import { CommunityType, UserTypeEdge } from "../../types";
import { COMMUNITIES_QUERY } from "../accounts/queries";

// Queries
import { USER_SEARCH_QUERY } from "./queries";

// Styles
import "./core.sass";

// Constants
import { DefaultPaginationLimit, FilterMode } from "../../constants";
import DesktopHeader from "../core/DesktopHeader";

const TITLE = "Find people to help you, and teach you new things";
const SEARCH_COPY = "Search by name or skill";

const PeopleView = () => {
  const [state, setState] = useState({
    query: "",
    filter: "",
    nodes: [],
    pageInfo: "",
    totalCount: 0,
    options: [],
  });

  const [executeSearch, { loading, error, data, fetchMore }] = useLazyQuery(
    USER_SEARCH_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data && data.userSearch) {
      setState({
        ...state,
        nodes: data.userSearch.edges.map((edge: UserTypeEdge) => edge.node),
        pageInfo: data.userSearch.pageInfo,
        totalCount: data.userSearch.totalCount,
      });
    }
  }, [data]); // eslint-disable-line

  const { error: errorCommunities, data: dataCommunities } =
    useQuery(COMMUNITIES_QUERY);

  const runQuery = () => {
    executeSearch({
      variables: {
        query: state.query,
        filter: state.filter,
        first: DefaultPaginationLimit,
        after: "",
      },
    });
  };

  // Initial set up.
  useEffect(() => {
    runQuery();
  }, []); // eslint-disable-line

  useEffect(() => {
    runQuery();
  }, [state.query]); // eslint-disable-line

  useEffect(() => {
    runQuery();
  }, [state.filter]); // eslint-disable-line

  useEffect(() => {
    if (dataCommunities && dataCommunities.communities) {
      setState({
        ...state,
        options: dataCommunities.communities.map((e: CommunityType) => [
          e.name,
          e.slug,
        ]),
      });
    }
  }, [dataCommunities]); // eslint-disable-line

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  const updateFilter = (filter: string) => {
    setState({
      ...state,
      filter: filter,
    });
  };

  // NOTE: Using guards for loading and error states
  if (error || errorCommunities) return null;

  const pageInfo = data ? data.userSearch.pageInfo : "";

  return (
    <React.Fragment>
      <DesktopHeader />
      <div className="tray three-column">
        <div className="col-left desktop">
          <div className="widgets">
            <Search
              filterMode={FilterMode.Full}
              filterOptions={state.options}
              filterPlaceholder="Everyone"
              placeholder={SEARCH_COPY}
              onChangeQuery={updateQuery}
              onChangeFilter={updateFilter}
              query={state.query}
              filter={state.filter}
            />
            <Greeting message="Let's see who's on Echo today." />
          </div>
        </div>
        <div className="col-center">
          <Navigation />
          <Header title={TITLE} />
          <Search
            filterMode={FilterMode.Full}
            filterOptions={state.options}
            filterPlaceholder="Everyone"
            placeholder={SEARCH_COPY}
            onChangeQuery={updateQuery}
            onChangeFilter={updateFilter}
            query={state.query}
            filter={state.filter}
          />
          <div className="profile cntrlse">
            <div className="controls"></div>
            {!loading && (
              <ProfileList
                users={state.nodes}
                totalCount={state.totalCount}
                more={pageInfo.hasNextPage}
                onLoadMore={() => {
                  if (pageInfo.hasNextPage) {
                    fetchMore({
                      variables: {
                        after: pageInfo.endCursor,
                      },
                    });
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className="col-right desktop"></div>
      </div>
    </React.Fragment>
  );
};

export default PeopleView;
