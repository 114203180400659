import React from "react";

type SimpleButtonProps = {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SimpleButton = ({ title, onClick }: SimpleButtonProps) => {
  return (
    <div className="wrap-button simple form-button">
      <button className={`simple btn`} onClick={onClick}>
        {title}
      </button>
    </div>
  );
};

export default SimpleButton;
