import React from "react";

// Logic
import { useMutation, useQuery } from "@apollo/client/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// Types
import { OfferType, UserType, LocationStateType } from "../../types";

// Styles
import "./Offer.sass";

// Components
import CancelButton from "../core/CancelButton";
import ActionButton from "../core/ActionButton";
import { SkillList } from "./SkillList";

// Assets
import { ReactComponent as ExploreSVG } from "../../assets/svg/icons/navigation/main_explore.svg";
import { ReactComponent as CaseSVG } from "../../assets/svg/icons/case.svg";
import { ReactComponent as HeartSVG } from "../../assets/svg/icons/heart.svg";
import { ReactComponent as PencilSVG } from "../../assets/svg/icons/pencil.svg";
import { ReactComponent as FileDeleteSVG } from "../../assets/svg/icons/file-delete.svg";
import messageSVGURL from "../../assets/svg/icons/button/message-plain.svg";

// Queries
import { OFFER_QUERY, USER_PROFILE_SHORT_QUERY } from "./queries";

// Constants
import { ROUTES } from "../../constants";
import {
  CREATE_DIALOGUE_MUTATION,
  DIALOGUES_QUERY,
} from "../conversation/queries";
import { setError } from "../core/utils";

type SimpleHeaderProps = {
  user: UserType;
  offer: OfferType;
  prevPath: string;
};

const SimpleHeader = ({ user, offer, prevPath }: SimpleHeaderProps) => {
  const navigate = useNavigate();

  const [createDialogue] = useMutation(CREATE_DIALOGUE_MUTATION, {
    onCompleted: (data) => {
      const dialogue = data.createDialogue.dialogue;
      navigate(`${ROUTES.MESSAGES}/${dialogue.id}`);
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: [{ query: DIALOGUES_QUERY }],
  });

  const action = user.isCurrentUser ? (
    <>
      <div className="edit-link">
        <Link
          to={{
            pathname: `${ROUTES.PROFILE}/${user.id}/${offer.id}/edit`,
          }}
          className="edit"
        >
          <PencilSVG /> Edit
        </Link>
        <Link
          to={{
            pathname: `${ROUTES.PROFILE}/${user.id}/${offer.id}/delete`,
          }}
          className="delete"
        >
          <FileDeleteSVG /> Delete
        </Link>
      </div>
    </>
  ) : (
    <ActionButton
      type="btn-message"
      iconURL={messageSVGURL}
      title="Message"
      onClick={() => {
        createDialogue({ variables: { userId: user.id } });
      }}
    />
  );
  return (
    <div className="header simple">
      <CancelButton pathname={prevPath || ROUTES.ACTIVITY} />
      {action}
    </div>
  );
};

type OfferProps = {
  user: UserType;
  offer: OfferType;
};

const Offer = ({ user, offer }: OfferProps) => {
  const { state } = useLocation();
  return (
    <div className="offer full contained">
      <SimpleHeader
        user={user}
        offer={offer}
        prevPath={(state as LocationStateType)?.prevPath}
      />
      <div className="user-info">
        <div className="visual">
          <div className="image">
            {user.profile?.picture && (
              <img src={user.profile?.picture} alt={user.name} />
            )}
          </div>
        </div>
        <div className="textual">
          <div className="details">
            <div className="name f-b">{user.profile?.preferredName}</div>
            {user.profile?.project && (
              <div className="project">
                <CaseSVG /> {user.profile?.project}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="title f-b">{offer.title}</div>
      <div className="meta">
        <span>
          <HeartSVG />
          {offer.taken} people have taken this offer
        </span>
        <br />
        <span>
          <ExploreSVG />
          Will take about {offer.price} hr(s)
        </span>
      </div>
      <div className="skill-list">
        <SkillList skills={offer.skills || []} />
      </div>
      {offer.picture?.length !== 0 && (
        <div className="picture">
          <img src={offer.picture} alt="" />
        </div>
      )}
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: offer.description || "" }}
      />
    </div>
  );
};

const OfferDetails = () => {
  const { userId, offerId } = useParams();
  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
  } = useQuery(USER_PROFILE_SHORT_QUERY, {
    variables: { id: userId },
  });
  const {
    loading: loadingOffer,
    error: errorOffer,
    data: dataOffer,
  } = useQuery(OFFER_QUERY, {
    variables: { id: offerId },
  });

  if (loadingUser || errorUser || loadingOffer || errorOffer) return null;

  return <Offer user={dataUser.user} offer={dataOffer.offer} />;
};

export default OfferDetails;
