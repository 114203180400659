import React from "react";

import { UserType } from "../../types";

import "./ProfileItem.sass";

import { Link } from "react-router-dom";

import OfferList from "./OfferList";
import { SkillEntryList } from "./SkillList";

import { ReactComponent as CaseSVG } from "../../assets/svg/icons/case.svg";
import { ReactComponent as HeartSVG } from "../../assets/svg/icons/heart.svg";
import { ReactComponent as PlusSVG } from "../../assets/svg/brand/plus.svg";

type ProfileItemProps = {
  user: UserType;
};

const ProfileItem = ({ user }: ProfileItemProps) => {
  const offersClass =
    user.profile?.offers && user.profile?.offers?.length > 0 ? "" : "empty";
  return (
    <div className="profile-item">
      <div className="user-info">
        <div className="visual">
          <div className="image">
            <Link
              to={{ pathname: `/profile/${user.id}` }}
              state={{ prevPath: window.location.pathname }}
            >
              {user.profile?.picture && (
                <img src={user.profile?.picture} alt={user.name} />
              )}
            </Link>
          </div>
          <div className="meta">
            <span className="time">
              <PlusSVG />
              {user.profile?.time}
            </span>
            <span className="taken">
              <HeartSVG />
              {user.profile?.taken}
            </span>
          </div>
        </div>
        <div className="textual">
          <div className="details">
            <div className="name">
              <Link
                to={{ pathname: `/profile/${user.id}` }}
                state={{ prevPath: window.location.pathname }}
              >
                {user.profile?.preferredName}
              </Link>
            </div>
            <div className="bio">{user.profile?.bio}</div>
            {user.profile?.project && (
              <div className="project">
                <CaseSVG /> {user.profile?.project}
              </div>
            )}
          </div>
        </div>
      </div>
      <SkillEntryList skills={user.profile?.skills || []} />
      <div className={`offers ${offersClass}`}>
        <OfferList user={user} />
      </div>
      <div className="profile-link f-b">
        <Link
          to={{ pathname: `/profile/${user.id}` }}
          state={{ prevPath: window.location.pathname }}
        >
          View full profile
        </Link>
      </div>
    </div>
  );
};

export default ProfileItem;
