import React from "react";

import { MessageType } from "../../types";

import { messageClass } from "./Message";

import "./TradeMessage.sass";

import { formatTime } from "../core/utils";

import PLUS_SVG_URL from "../../assets/svg/brand/plus.svg";

type TradeMessageProps = {
  message: MessageType;
};

export const TradeMessage = ({ message }: TradeMessageProps) => {
  const unitString =
    message.trade && message.trade?.units > 1 ? "Echos" : "Echo";
  return (
    <div className={`trade ${messageClass(message)}`}>
      <div className="heading"></div>
      <div className="image">
        {message.author?.profile?.picture && (
          <img
            src={message.author?.profile?.picture}
            alt={message.author?.profile?.preferredName}
          />
        )}
      </div>
      <div className="content f-b">
        {message.trade?.recipient?.profile?.preferredName} gave{" "}
        <img src={PLUS_SVG_URL} alt="Echo Symbol" />
        {message.trade?.units} {unitString}
      </div>
      <div className="status">
        <div className="timestamp" data-raw={message.timestamp}>
          {formatTime(message.timestamp)}
        </div>
      </div>
    </div>
  );
};
