import React from "react";
import { useNavigate } from "react-router-dom";

import icon from "../../assets/svg/icons/button/cancel.svg";

type CancelButtonProps = {
  pathname?: string;
  onClick?: () => void;
  label?: string;
};

const CancelButton = ({
  pathname,
  onClick,
  label = "Cancel",
}: CancelButtonProps) => {
  const navigate = useNavigate();
  return (
    <div className="wrap-button cancel">
      <button
        type="button"
        className="cancel btn"
        id="cancel"
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          pathname ? navigate(pathname, { replace: true }) : navigate(-1);
        }}
      >
        <img src={icon} alt={label} />
      </button>
    </div>
  );
};

export default CancelButton;
