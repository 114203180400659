import React from "react";

import "./MessageButton.sass";

import messageSVGURL from "../../assets/svg/icons/button/message.svg";

type MessageButtonProps = {
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const MessageButton = ({ label = "New", onClick }: MessageButtonProps) => {
  return (
    <button type="button" className="new-message btn" id="message" onClick={onClick}>
      <img src={messageSVGURL} alt={label} />
      <span>{label}</span>
    </button>
  );
};

export default MessageButton;
