import React, { useState } from "react";

import "./Header.sass";

import plusSVGURL from "../../assets/svg/brand/plus.svg";

import { BalanceToast } from "./BalanceToast";

type BalanceProps = {
  balance: number;
};

const Balance = ({ balance }: BalanceProps) => {
  const [visible, updateVisibility] = useState(false);
  const handleClick = () => {
    updateVisibility(!visible);
  };
  return (
    <div className="balance status" onClick={handleClick}>
      <img src={plusSVGURL} alt="Echos" />
      <span>{balance}</span>
      {visible && (
        <BalanceToast
          show={visible}
          balance={balance}
          onCloseClick={handleClick}
        />
      )}
    </div>
  );
};

export default Balance;
