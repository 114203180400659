import { gql } from "@apollo/client";

export const TOKEN_AUTH_MUTATION = gql`
  mutation TokenAuthMutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      success
      errors
      token
      refreshToken
      user {
        id
        username
        email
      }
    }
  }
`;

export const REVOKE_TOKEN_MUTATION = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      success
      errors
    }
  }
`;

export const RESEND_ACTIVATION_EMAIL_MUTATION = gql`
  mutation ResendActivationEmail($email: String!) {
    resendActivationEmail(email: $email) {
      success
      errors
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register($email: String!, $password: String!) {
    register(
      email: $email
      username: $email
      password1: $password
      password2: $password
    ) {
      success
      errors
      token
      refreshToken
    }
  }
`;

export const UPDATE_USER_COMMUNITIES_MUTATION = gql`
  mutation UpdateUserCommunities($communities: [String]) {
    updateUserCommunities(communities: $communities) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          communities {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const PASSWORD_RESET_MUTATION = gql`
  mutation PasswordReset(
    $token: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;

export const VERIFY_ACCOUNT_MUTATION = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL_MUTATION = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const UPDATE_USER_LOCATION_MUTATION = gql`
  mutation UpdateUserLocation($location: String) {
    updateUserLocation(location: $location) {
      status
      formErrors
      user {
        id
        firstName
        lastName
        profile {
          id
          location
        }
      }
    }
  }
`;

export const COMMUNITIES_QUERY = gql`
  query Communities {
    communities {
      name
      slug
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      firstName
      lastName
      email
      verified
      profile {
        id
        preferredName
        picture
        balance
        complete
      }
    }
  }
`;
