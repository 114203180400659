import React, { useEffect /*SyntheticEvent*/, useRef, useState } from "react";

import { useQuery } from "@apollo/client";

import { MessageType } from "../../types";

import "./Conversation.sass";

// Components
import { TradeMessage } from "./TradeMessage";
import { TradeInput } from "./TradeInput";
import { MessageInput } from "./MessageInput";
import { Message } from "./Message";
import { Modal } from "../core/Modal";

// Queries
import { DIALOGUE_QUERY } from "./queries";
import { NOTIFICATION_SUBSCRIPTION } from "../core/queries";
import { ME_QUERY } from "../accounts/queries";

// Helpers
import { modes } from "./reducer";

// Assets
import chevronLeftSVGURL from "../../assets/svg/icons/navigation/chevron_left_24px.svg";
import echobotSVGURL from "../../assets/svg/icons/echobot.svg";

// Constants
import { useLocation, useNavigate } from "react-router-dom";

interface ConversationProps {
  id: string;
}

const Conversation = ({ id }: ConversationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentMode, updateMode] = useState(modes.SEND_MESSAGE);

  const { data: dataMe } = useQuery(ME_QUERY);

  const { loading, error, data, subscribeToMore } = useQuery(DIALOGUE_QUERY, {
    variables: { id: id },
  });

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    subscribeToMore({
      document: NOTIFICATION_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.notificationSubscription;
        return Object.assign({}, prev, {
          dialogue: {
            messages: [newMessage, ...prev.dialogue.messages],
          },
        });
      },
    });
  }, [subscribeToMore]);

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const initSendMessage = () => {
    updateMode(modes.SEND_MESSAGE);
  };

  const initCreateTrade = () => {
    updateMode(modes.CREATE_TRADE);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (loading || error) return null;

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleTradeComplete = () => {
    initSendMessage();
  };

  const handleTradeCancel = () => {
    initSendMessage();
  };

  const handleAddButtonClick = () => {
    initCreateTrade();
  };

  const messages = (
    <React.Fragment>
      {data.dialogue.messages?.map((message: MessageType, index: number) => {
        return message.trade ? (
          <TradeMessage message={message} key={index} />
        ) : (
          <Message message={message} key={index} />
        );
      })}
      <div ref={messagesEndRef} />
    </React.Fragment>
  );

  const placeholderMessage =
    "Hey 👋. This is the beginning of your conversation! You can get started by sending a quick message. When you want to send them some Echos, you can click the ‘plus’ button bottom left. Remember 1 Echo = 1 hour";

  const placeholderStatus = "Only you can see this";

  const placeholder = (
    <div className="message bot">
      <div className="heading"></div>
      <div className="image">
        <img src={echobotSVGURL} alt="Echo" />
      </div>
      <div className="content">{placeholderMessage}</div>
      <div className="status">
        <div className="info">{placeholderStatus}</div>
      </div>
    </div>
  );

  return (
    <div className="conversation-view">
      <div className="conversation">
        <div id="conversation-heading">
          <span className="back">
            <img
              src={chevronLeftSVGURL}
              alt="Back"
              onClick={handleBackButtonClick}
            />
          </span>
          <span className="title">{data.dialogue?.formattedTitle}</span>
        </div>
        <div id="conversation-log">
          {data.dialogue.messages.length ? messages : placeholder}
        </div>
      </div>
      {currentMode === modes.SEND_MESSAGE ? (
        <MessageInput
          dialogue={data.dialogue}
          onAddButtonClick={handleAddButtonClick}
        />
      ) : (
        <Modal show={true} closeModal={handleTradeCancel} className="trade">
          <TradeInput
            dialogue={data.dialogue}
            user={dataMe.me}
            onCancel={handleTradeCancel}
            onComplete={handleTradeComplete}
            prevPath={location.pathname}
          />
        </Modal>
      )}
    </div>
  );
};

export default Conversation;
