import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import Navigation from "../core/Navigation";
import Header from "../core/Header";
import Search from "../core/Search";

import DialogueList from "./DialogueList";
import MessageButton from "./MessageButton";

import { DialogueType } from "../../types";
import { DIALOGUES_SEARCH_QUERY } from "./queries";

import "./core.sass";
import { ROUTES } from "../../constants";

interface MessagesProps {
  dialogues?: DialogueType[];
}

const TITLE = "Message the Echo community to arrange helping each other";
const SEARCH_COPY = "Search messages";
const SEARCH_MIN_LENGTH = 0;

const Messages = ({ dialogues }: MessagesProps) => {
  useEffect(() => {
    document.body.classList.add("messages");
    return () => {
      document.body.classList.remove("messages");
    };
  }, []);

  const navigate = useNavigate();
  const [state, setState] = useState({
    query: "",
    filter: "",
    dialogues: [],
  });

  const [executeSearch, { data }] = useLazyQuery(DIALOGUES_SEARCH_QUERY);

  useEffect(() => {
    if (data) {
      setState({ ...state, dialogues: data.dialoguesSearch || [] });
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (state.query.length > SEARCH_MIN_LENGTH) {
      executeSearch({
        variables: { query: state.query },
      });
    } else {
      setState({
        ...state,
        query: "",
      });
    }
  }, [state.query]); // eslint-disable-line

  const updateQuery = (query: string) => {
    setState({
      ...state,
      query: query,
    });
  };

  const updateFilter = (filter: string) => {
    setState({
      ...state,
      filter: filter,
    });
  };

  const _dialogues = state.query.length ? state.dialogues : dialogues;

  return (
    <div className="messages-comp">
      <Navigation />
      <Header title={TITLE} />
      <div className="col-header desktop">
        <div className="col-header-wrap">
          <h4>Messages</h4>
        </div>
      </div>
      <div className="controls">
        <MessageButton
          label="New message"
          onClick={() => navigate(`${ROUTES.MESSAGES}/new`)}
        />
      </div>
      <Search
        placeholder={SEARCH_COPY}
        onChangeQuery={updateQuery}
        onChangeFilter={updateFilter}
        query={state.query}
      />
      <div className="messages cntrlse">
        <DialogueList dialogues={_dialogues} />
      </div>
    </div>
  );
};

export default Messages;
