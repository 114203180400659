import React from "react";
import { Link } from "react-router-dom";

import { DialogueType, NotificationType, Type, UserType } from "../../types";

import useNotification from "../core/Notification";
import { formatTime } from "../core/utils";
import { ROUTES } from "../../constants";

import "./Dialogue.sass";

interface DialogueProps {
  dialogue: DialogueType;
  selected?: boolean;
  notifications?: NotificationType[];
}

const Dialogue = ({
  dialogue,
  selected,
  notifications = [],
}: DialogueProps) => {
  const { removeNotification } = useNotification();

  const handleClick = () => {
    if (notifications.length) {
      removeNotification(notifications[0].id);
    }
  };

  const formatTradeMessage = (
    user: UserType | undefined,
    time: string | undefined
  ) => {
    if (user !== undefined && time !== undefined) {
      return `${user.profile?.preferredName} gave ${time} Echo${
        parseInt(time) > 1 ? "s" : ""
      }`;
    }
    return "";
  };

  const mostRecentMessage = () => {
    if (dialogue.messages?.length) {
      const lastMessage = dialogue.messages[dialogue.messages.length - 1];
      if (lastMessage.trade == null) {
        return lastMessage.text;
      }
      return formatTradeMessage(
        lastMessage.trade?.recipient,
        `${lastMessage.trade.units}`
      );
    }
    return dialogue.lastMessage;
  };

  const lastMessage = notifications.length
    ? notifications[0].type === Type.MESSAGE
      ? notifications[0].text
      : formatTradeMessage(notifications[0].author, notifications[0].text)
    : mostRecentMessage();

  const timestamp = notifications.length
    ? new Date().toISOString()
    : dialogue.timestamp;

  return (
    <div
      className={`dialogue${notifications.length ? " unread" : ""}${
        selected ? " active" : ""
      }`}
    >
      <Link
        to={{ pathname: `${ROUTES.MESSAGES}/${dialogue.id}` }}
        onClick={handleClick}
      >
        <div className="image">
          {dialogue.defaultPicture && (
            <img src={dialogue.defaultPicture} alt={dialogue.formattedTitle} />
          )}
        </div>
        <div className="preview">
          <div className="heading">
            <div className="name f-b">{dialogue.formattedTitle}</div>
            <div className="timestamp c-g">{formatTime(timestamp)}</div>
          </div>
          <div className="last-message f-s-1 c-g">{lastMessage}</div>
        </div>
      </Link>
    </div>
  );
};

export default Dialogue;
