import React, { useState } from "react";
import { Link } from "react-router-dom";

import LogIn from "./LogIn";

// Assets
import { ReactComponent as LogoSVG } from "../../assets/svg/brand/logo-with-tag.svg";

import AuthButton from "./AuthButton";

const LogoHeader = () => (
  <div className="logo-header">
    <LogoSVG className="logo" />
  </div>
);

const LeadCopy = () => (
  <div className="lead-copy f-b">
    Connect with a community
    <br /> that makes you tick
  </div>
);

const TextSignUp = () => (
  <div className="text-footnote">
    Don’t have an account? <Link to={{ pathname: "/signup" }}>Sign up</Link>
  </div>
);

enum modes {
  START,
  LOGIN,
}

const LoginView = () => {
  const [state, setState] = useState({ mode: modes.START });
  const handleEmailClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setState({ ...state, mode: modes.LOGIN });
  };
  return (
    <div className="auth contained">
      {state.mode === modes.START ? (
        <div className="start-mode">
          <LogoHeader />
          <LeadCopy />
          <AuthButton onClick={handleEmailClick} title={"Login with email"} />
          <TextSignUp />
        </div>
      ) : (
        <LogIn />
      )}
    </div>
  );
};

export default LoginView;
