import { gql } from "@apollo/client";

// NOTE: Convert node into a type
export const ACTIONS_QUERY = gql`
  query Actions($filter: String, $first: Int, $after: String) {
    actions(communities: $filter, first: $first, after: $after) {
      edges {
        node {
          id
          actor {
            __typename
            ... on UserType {
              id
              profile {
                id
                preferredName
                bio
                picture
                project
              }
            }
          }
          target {
            __typename
            ... on OfferType {
              id
              title
              short
              description
              price
              skills {
                id
                title
              }
            }
            ... on TradeType {
              id
              supplier {
                id
                profile {
                  id
                  preferredName
                  bio
                  picture
                  project
                }
              }
              recipient {
                id
                profile {
                  id
                  preferredName
                  bio
                  picture
                  project
                }
              }
              offer {
                id
                title
              }
              note {
                id
                text
                timeCreated
              }
            }
          }
          verb
          timestamp
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
